import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// 测试环境
if (process.env.NODE_ENV === 'development') {
  console.log("env", process.env)
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  /**
   * React.StrictMode 会在开发环境下执行两次render
   */
  // <React.StrictMode>
  //   <App/>
  // </React.StrictMode>
  <App/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
