import {useContext, useEffect, useState} from "react";
import HomeTemplateContext from "../context/HomeTemplateContext";
import userService from "../../service/userService";
import {message, Progress} from "antd";
import {getAuth} from "firebase/auth";
import CreditIcon from "../component/CreditIcon";
import {formatPrice} from "../../utils/ada";

const SettingView = () => {

  const {
    setIsRechargeCreditModalOpen,
  } = useContext(HomeTemplateContext)

  const [userProfile, setUserProfile] = useState(null)
  const [inputValue, setInputValue] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editAPIKey, setEditAPIKey] = useState(null)

  const getProfile = () => {
    userService.profile((res) => {
      setUserProfile(res.data)
    }, (error) => {
      console.log(error)
    })
  }

  const saveOpenAI = () => {
    userService.saveOpenAPI(inputValue, (res) => {
      message.success('save success')
      getProfile()
    }, (error) => {
      console.log(error)
    })
  }
  const deleteOpenAI = () => {
    userService.deleteOpenAI((res) => {
      message.success('delete success')
      getProfile()
    }, (error) => {
      console.log(error)
    })
  }

  const saveDeepl = () => {
    userService.saveDeepl(inputValue, (res) => {
      message.success('save success')
      getProfile()
    }, (error) => {
      console.log(error)
    })
  }

  const deleteDeepl = () => {
    userService.deleteDeepl((res) => {
      message.success('delete success')
      getProfile()
    }, (error) => {
      console.log(error)
    })
  }

  const maskAPIKey = (key) => {
    if (key == null) {
      return null
    }
    if (key.length < 10) {
      return key
    }
    return key.substring(0, 4) + '****' + key.substring(key.length - 4)
  }

  const calculateProcess = (progress, total) => {
    if (progress === 0) {
      return 0
    }
    return progress / total * 100
  }

  useEffect(() => {
    getProfile()
  }, [])

  if (userProfile == null) {
    return null
  }
  return (
    <div className={"flex flex-col px-40"}>
      <h1>Setting</h1>
      <div className={"flex flex-row mb-4"}>
        <span
          className={"w-[150px]"}>Email:</span><span>{getAuth().currentUser == null ? '' : getAuth().currentUser.email}</span>
      </div>
      <div className={"flex flex-row mb-4"}>
        <span className={"w-[150px]"}>Credit:</span>
        <span className={"ml-1"}>{}
          <CreditIcon number={formatPrice(userProfile?.account?.credit)}/>
          <a
            className={"ml-2"}
            onClick={() => {
              setIsRechargeCreditModalOpen(true)
            }}>Recharge Credit</a>
        </span>
      </div>
      {/*<div className={"flex flex-row mb-4"}>*/}
      {/*  <span className={"w-[150px]"}>Free Questions :</span>*/}
      {/*  <Progress className={"w-[180px]"} percent={calculateProcess(userProfile?.account?.freeQuestions, 20)}*/}
      {/*            size="small" showInfo={false}/>*/}
      {/*  <span className={"ml-2"}>{userProfile?.account?.freeQuestions}/20</span>*/}
      {/*</div>*/}
      {/*<div className={"flex flex-row mb-4"}>*/}
      {/*  <span className={"w-[150px]"}>Free Translations :</span>*/}
      {/*  <Progress className={"w-[180px]"} percent={calculateProcess(userProfile?.account?.freeTranslations, 20)}*/}
      {/*            size="small" showInfo={false}/>*/}
      {/*  <span className={"ml-2"}>{userProfile?.account?.freeTranslations}/20</span>*/}
      {/*</div>*/}
      {/*<div className={"flex flex-row mb-4"}>*/}
      {/*  <span className={"w-[150px]"}>Free Doc Pages :</span>*/}
      {/*  <Progress className={"w-[180px]"} percent={calculateProcess(userProfile?.account?.freeDocPages, 4)} size="small"*/}
      {/*            showInfo={false}/>*/}
      {/*  <span className={"w-[180px]"}>{userProfile?.account?.freeDocPages}</span>*/}
      {/*</div>*/}
      <div className={"flex flex-row mb-4"}>
        <a onClick={async () => {
          await getAuth().signOut()
          message.success('Logout success')
          setTimeout(() => {
            window.location.href = '/'
          })
        }}>Logout</a>
      </div>
      {/*<div className={"flex flex-col mb-6"}>*/}
      {/*  <div className={"flex flex-row items-center mb-1"}>*/}
      {/*  <span*/}
      {/*    className={"w-[120px]"}*/}
      {/*  >OpenAI API key: </span>*/}
      {/*    {(userProfile.apiKeys == null ||*/}
      {/*      userProfile.apiKeys.openAI == null ||*/}
      {/*      userProfile.apiKeys.openAI === "") && <div>*/}
      {/*      <Button*/}
      {/*        type={"primary"}*/}
      {/*        onClick={() => {*/}
      {/*          setEditAPIKey('openAI')*/}
      {/*          setIsModalOpen(true)*/}
      {/*        }}*/}
      {/*      >Add</Button>*/}
      {/*    </div>}*/}

      {/*    {(userProfile.apiKeys != null &&*/}
      {/*      userProfile.apiKeys.openAI != null &&*/}
      {/*      userProfile.apiKeys.openAI !== "") && <div>*/}
      {/*      <span className={"text-sm"}>{maskAPIKey(userProfile.apiKeys.openAI)}</span>*/}
      {/*      <EditOutlined*/}
      {/*        onClick={() => {*/}
      {/*          setEditAPIKey('openAI')*/}
      {/*          setIsModalOpen(true)*/}
      {/*        }}*/}
      {/*        className={"ml-4 mr-2"}*/}
      {/*        style={{fontSize: '18px'}}/>*/}
      {/*      <Popconfirm*/}
      {/*        title={"Are you sure to delete?"}*/}
      {/*        onConfirm={() => {*/}
      {/*          deleteOpenAI()*/}
      {/*        }}>*/}
      {/*        <DeleteOutlined*/}
      {/*          className={"mr-2"}*/}
      {/*          style={{fontSize: '18px'}}/>*/}
      {/*      </Popconfirm>*/}
      {/*    </div>*/}
      {/*    }*/}
      {/*  </div>*/}
      {/*  <a className={"ml-[120px]"}>How to get the OpenAI API Key?</a>*/}
      {/*</div>*/}
      {/*<div className={"flex flex-col mb-6"}>*/}
      {/*  <div className={"flex flex-row items-center mb-1"}>*/}
      {/*  <span*/}
      {/*    className={"w-[120px]"}*/}
      {/*  >DeepL API key: </span>*/}
      {/*    {(userProfile.apiKeys == null ||*/}
      {/*      userProfile.apiKeys.deepl == null ||*/}
      {/*      userProfile.apiKeys.deepl === "") && <div>*/}
      {/*      <Button*/}
      {/*        type={"primary"}*/}
      {/*        onClick={() => {*/}
      {/*          setEditAPIKey('deepl')*/}
      {/*          setIsModalOpen(true)*/}
      {/*        }}*/}
      {/*      >Add</Button>*/}
      {/*    </div>}*/}

      {/*    {(userProfile.apiKeys != null &&*/}
      {/*      userProfile.apiKeys.deepl != null &&*/}
      {/*      userProfile.apiKeys.deepl !== "") && <div>*/}
      {/*      <span className={"text-sm"}>{maskAPIKey(userProfile.apiKeys.deepl)}</span>*/}
      {/*      <EditOutlined*/}
      {/*        onClick={() => {*/}
      {/*          setEditAPIKey('deepl')*/}
      {/*          setIsModalOpen(true)*/}
      {/*        }}*/}
      {/*        className={"ml-4 mr-2"}*/}
      {/*        style={{fontSize: '18px'}}/>*/}
      {/*      <Popconfirm*/}
      {/*        title={"Are you sure to delete?"}*/}
      {/*        onConfirm={() => {*/}
      {/*          deleteDeepl()*/}
      {/*        }}>*/}
      {/*        <DeleteOutlined*/}
      {/*          className={"mr-2"}*/}
      {/*          style={{fontSize: '18px'}}/>*/}
      {/*      </Popconfirm>*/}
      {/*    </div>*/}
      {/*    }*/}

      {/*  </div>*/}
      {/*  <a className={"ml-[120px]"}>How to get the DeepL API Key?</a>*/}
      {/*</div>*/}
      {/*<Modal title="Edit API KEy"*/}
      {/*       open={isModalOpen}*/}
      {/*       onOk={() => {*/}
      {/*         if (editAPIKey === 'deepl') {*/}
      {/*           saveDeepl()*/}
      {/*         } else if (editAPIKey === 'openAI') {*/}
      {/*           saveOpenAI()*/}
      {/*         }*/}
      {/*         setIsModalOpen(false);*/}
      {/*         setInputValue('')*/}
      {/*       }}*/}
      {/*       onCancel={() => {*/}
      {/*         setIsModalOpen(false);*/}
      {/*         setInputValue('')*/}
      {/*       }}>*/}
      {/*  <Input*/}
      {/*    placeholder={"Please input your API Key"}*/}
      {/*    value={inputValue}*/}
      {/*    onChange={(e) => {*/}
      {/*      setInputValue(e.target.value)*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</Modal>*/}

    </div>
  )
}
export default SettingView
