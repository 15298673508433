import httpRequest from '../utils/httpRequest';

export default class UserService {

  static myDetail(success, error) {
    httpRequest.get({
      url: '/api/user/my/detail',
    }).then((res) => {
      success(res);
    }).catch((res) => {
      error(res);
    })
  }

  static myDetailAsync() {
    return httpRequest.get({
      url: '/api/user/my/detail',
    })
  }

  static async myDetailAsync() {
    return await httpRequest.get({
      url: '/api/user/my/detail',
    })
  }

  static async getUserId() {
    return await httpRequest.get({
      url: '/api/user/getUserId',
    })
  }

  static profile(success, error) {
    httpRequest.get({
      url: '/api/user/profile',
    }).then((res) => {
      success(res);
    }).catch((res) => {
      error(res);
    })
  }

  static saveOpenAPI(openAI, success, error) {
    httpRequest.post({
      url: '/api/user/saveOpenAI',
      params: {
        openAI: openAI,
      }
    }).then((res) => {
      success(res);
    }).catch((res) => {
      error(res);
    })
  }

  static saveDeepl(deepl, success, error) {
    httpRequest.post({
      url: '/api/user/saveDeepl',
      params: {
        deepl: deepl,
      }
    }).then((res) => {
      success(res);
    }).catch((res) => {
      error(res);
    })
  }

  static deleteOpenAI(success, error) {
    httpRequest.post({
      url: '/api/user/deleteOpenAI',
    }).then((res) => {
      success(res);
    }).catch((res) => {
      error(res);
    })

  }

  static deleteDeepl(success, error) {
    httpRequest.post({
      url: '/api/user/deleteDeepl',
    }).then((res) => {
      success(res);
    }).catch((res) => {
      error(res);
    })
  }


  /**
   * Default query 30 days
   * @return {Promise}
   */
  static getCreditPayments() {
    return httpRequest.get({
      url: '/api/user/getCreditPayments',
    })
  }

  static updateLanguage(language, success, error) {
    httpRequest.post({
      url: '/api/user/updateLanguage',
      params: {
        language: language,
      }
    }).then((res) => {
      success(res);
    }).catch((res) => {
      error(res);
    })
  }

  static checkCredit(){
    return httpRequest.get({
      url: '/api/user/checkCredit',
    })
  }
}
