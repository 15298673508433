import {Input, List, message, Modal, Popconfirm} from "antd";
import React, {useEffect, useState} from "react";
import pdfShareService from "../../service/pdfShareService";
import {DeleteOutlined} from "@ant-design/icons";


const SharePDFModal = ({pdfId, open, onOk, onCancel}) => {

  const [emailListInputValue, setEmailListInputValue] = useState(null)
  const [pdfShareEntity, setPdfShareEntity] = useState(null)

  useEffect(() => {
    async function getPdfShareEntity() {
      const res = await pdfShareService.getByPdfId(pdfId)
      setPdfShareEntity(res.data)
    }

    if (open) {
      getPdfShareEntity()
    }
  }, [open]);


  return (
    <Modal
      title={`Share Your Paper`}
      open={open}
      onOk={async () => {
        if (pdfShareEntity != null && pdfShareEntity.shareUrl) {
          await navigator.clipboard.writeText(pdfShareEntity.shareUrl)
          message.success('Copied to clipboard')
        }
        onOk()
      }}
      cancelText={"Finish"}
      okText={"Copy Link"}
      onCancel={async () => {
        onCancel()
      }}>
      <Input
        value={emailListInputValue}
        placeholder="Enter email"
        onChange={(e) => {
          setEmailListInputValue(e.target.value)
        }}
        onKeyDown={async (e) => {
          if (e.keyCode === 13) {
            //check if email is valid
            if (emailListInputValue.indexOf('@') === -1) {
              message.error('Please enter a valid email')
              return
            }
            const res = await pdfShareService.addEmail(pdfShareEntity.pdfId, emailListInputValue)
            setPdfShareEntity(res.data)
            setEmailListInputValue('')
          }
        }}
      />
      <h3>Grant access to:</h3>
      {pdfShareEntity != null && pdfShareEntity.shareToEmailList.map((item) => {
        return (
          <div
            key={item}
            className={"flex justify-between"}>
            <p>{item}</p>
            <Popconfirm
              title={"Are you sure to remove access?"}
              onConfirm={async () => {
                try {
                  const res = await pdfShareService.removeEmail(pdfShareEntity.pdfId, item)
                  setPdfShareEntity(res.data)
                  message.success('remove access success')
                } catch (e) {
                  message.error(e.msg)
                }
              }}>
              <DeleteOutlined/>
            </Popconfirm>
          </div>
        )
      })}
    </Modal>
  )
}
export default SharePDFModal
