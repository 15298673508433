import React from 'react'
import {Col, Row} from 'antd'
import './resume.css'
import resumeCalling from '../assets/resume_calling.png'
import resumeHome from '../assets/resume_home.png'
import resumeIm from '../assets/resume_im.png'
import resumeRoom from '../assets/resume_room.png'
import resumeTweet from '../assets/resume_tweet.png'
import resumeHellobikeHome from '../assets/resume_hellobike_home.pic.jpg'
import resumeHellobikeTweet from '../assets/resume_hellobike_tweet.pic.jpg'
import resumeHellobikeWallet from '../assets/resume_hellobike_wallet.pic.jpg'
import resumeHellobikeHike from '../assets/resume_hellobike_hike.jpg'
import resumeHellobikeHike2 from '../assets/resume_hellobike_hike2.pic.jpg'


export default class Resume extends React.Component {
  status = {
    isJiumuDisplay: true,
    isHellobikeDisplay: true,
  }

  componentDidMount() {
    document.title = 'Allenyan\'s Resume'
  }

  renderJiumoMore() {
    let show = this.status.isJiumuDisplay || false;
    if (show) {
      return (
        <div className="resume_collapsible">
          <img src={resumeHome}/>
          <img src={resumeIm}/>
          <img src={resumeCalling}/>
          <img src={resumeTweet}/>
          <img src={resumeRoom}/>
        </div>
      )
    } else {
      return <p className="resume_more_information" onClick={this.onJiumuClick}>Show More</p>
    }
  }

  renderHellobikeMore() {
    let show = this.status.isHellobikeDisplay || false;
    if (show) {
      return (
        <div className="resume_collapsible">
          <img src={resumeHellobikeHome}/>
          <img src={resumeHellobikeTweet}/>
          <img src={resumeHellobikeWallet}/>
          <img src={resumeHellobikeHike}/>
          <img src={resumeHellobikeHike2}/>
        </div>
      )
    } else {
      return <p className="resume_more_information" onClick={this.onHellobikeClick}>Show More</p>
    }

  }

  onJiumuClick = () => {
    this.status.isJiumuDisplay = !this.status.isJiumuDisplay;
    this.forceUpdate()
  }
  onHellobikeClick = () => {
    this.status.isHellobikeDisplay = !this.status.isHellobikeDisplay;
    this.forceUpdate()
  }

  render() {
    return (
      <div className="resume_container">
        <Row align={"middle"} justify={"center"}>
          <Col xs={24} sm={24} md={12}>
            <div>
              <h1>Ligang Yan</h1>
              {/*<img src={avatar} className="resume_avatar"/>*/}
              <h2>Personal information</h2>
              <ul>
                <li>Address: Halifax, NS B3H 2P1, Canada 🇨🇦 <a
                  href="https://goo.gl/maps/DKH6sMysNv9eNqig7">View
                  at Google Map</a></li>
                <li>E-mail: <a href="mailto:wsyanligang@gmail.com">wsyanligang@gmail.com</a></li>
                <li>Tel: <a href="tel:902-989-5131">902-989-5131</a></li>
              </ul>
              <h2>Summary</h2>
              <ul>
                <li>Experienced software engineer and Android application developer with over 11 years of experience
                  in e-commerce, social media, live streaming, and travel applications.
                </li>
                <li> Skilled in backend service development and bug fixing over 4 years.</li>
                <li> Proficient in Agile project management and release processes over 6 years</li>
              </ul>
              <h2>Work experience</h2>
              <div className="resume_card">
                <h3>Full Stack Developer</h3>
                <p>Hangzhou Jiumu Information Technology Co., Ltd. - Hangzhou China</p>
                <p>March 2023 to Present</p>
                <ul>
                  <li>Defined requirements and developed two voice-based social media applications using Kotlin and
                    Java.
                  </li>
                  <li>Designed and deployed Android applications and backend services.</li>
                  <li>Conducted bug fixes and performance optimization for these applications.</li>
                </ul>
                {this.renderJiumoMore()}
              </div>

              <div className="resume_card">
                <img src="https://m.hellobike.com/resource/helloyun/12399/QgoaP_header__logo--colored.png"
                     className="resume_hello_logo"/>
                <h3>Senior Android Developer</h3>
                <p><a href="https://www.hello-inc.com/">Shanghai Hellobike Information Technology Co., Ltd.</a> -
                  Shanghai
                  China</p>
                <p>April 2021 to March 2023</p>
                <ul>
                  <li>Planned, designed, and implemented efficient and maintainable new features for the Carpooling
                    Service
                    Module in Java and Kotlin.
                  </li>
                  <li>Maintained, implemented, and researched internal libraries, SDKs, and systems.</li>
                  <li>Optimized application performance metrics such as launch time, crash rates, ANR rates, and memory
                    usage.
                  </li>
                  <li>Collaborated with QA teams for code testing.</li>
                </ul>
                {this.renderHellobikeMore()}
              </div>

              <div className="resume_card">
                <img src="https://s10.mogucdn.com/mlcdn/c45406/220221_3a7fk34bdehdbk3c76j24j18l4g23_192x60.png"
                     className="resume_mogu_logo"
                />
                <h3>Senior Android Developer</h3>
                <p><a href="https://www.mogu.com/">Hangzhou Mogu Information Technology Co., Ltd.</a> - Hangzhou China
                </p>
                <p>January 2014 to September 2019</p>
                <ul>
                  <li>Planned, designed, and implemented efficient code using Java and Kotlin in the Android
                    Application.
                  </li>
                  <li>Developed and implemented complex user interface components for Android devices.</li>
                  <li>Optimized performance and troubleshooted bugs.</li>
                  <li>Collaborated with QA teams for code testing.</li>
                </ul>
              </div>

              <h2>Education</h2>
              <div className="resume_card">
                <h3>Bachelor's in Computer Science</h3>
                <p>Ningbo University of Finance & Economics - Ningbo China </p>
                <p>September 2008 to July 2012 </p>
              </div>
              <h2>Skills</h2>
              <div className="resume_card">
                <h3>Programing Languages</h3>
                <ul>
                  <li>Java/Kotlin - Expert</li>
                  <li>JavaScript/HTML/CSS - Proficient</li>
                  <li>Node.js - Proficient</li>
                  {/*<li>Python - Advanced</li>*/}
                  {/*<li>C/C++ - Intermediate</li>*/}
                  {/*<li>ObjectC/Swift - Intermediate</li>*/}
                </ul>
              </div>
              <div className="resume_card">
                <h3>Frameworks</h3>
                <ul>
                  <li>Android - Expert</li>
                  <li>React - Proficient</li>
                  <li>Spring Boot - Proficient</li>
                  <li>MySQL - Advanced</li>
                </ul>
              </div>
              <div className="resume_card">
                <h3>Tools</h3>
                <ul>
                  <li>Git - Expert</li>
                  <li>Gradle - Expert</li>
                  <li>Android Studio - Expert</li>
                  <li>Maven - Expert</li>
                  <li>IntelliJ IDEA - Expert</li>
                  <li>DataGrip- Expert</li>
                  <li>Linux - Expert</li>
                  <li>MacOS - Expert</li>
                  <li>MySQL - Proficient</li>
                  <li>Redis - Proficient</li>
                  <li>Docker - Advanced</li>
                  <li>Sketch - Advanced</li>
                </ul>
              </div>
              <h2>Languages</h2>
              <ul>
                <li>Mandarin - Native</li>
                <li>English - Advanced (IELTS 5.0)</li>
              </ul>
              <h2>Links</h2>
              <ul>
                <li>Home Page: <a href="http://www.allenyan.ca">http://www.allenyan.ca</a></li>
                <li>Github: <a href="https://github.com/AllenYon">https://github.com/AllenYon</a></li>
                <li>Youtube: <a
                  href="https://www.youtube.com/channel/UCU22oBJnvAtEb-7JS_5Yj8Q">https://www.youtube.com/channel/UCU22oBJnvAtEb-7JS_5Yj8Q</a>
                </li>
                <li>Indeed: <a
                  href="https://profile.indeed.com/p/ligangy-lgfj12r">https://profile.indeed.com/p/ligangy-lgfj12r</a>
                </li>
                <li>LinkedIn: <a
                  href="https://www.linkedin.com/in/allen-yan-50861876">https://www.linkedin.com/in/allen-yan-50861876</a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

}
