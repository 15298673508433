import React, {useContext, useEffect, useState} from 'react';
import {Link,} from 'react-router-dom';
import {Button, Dropdown, List, Menu, message, Modal, Select, Spin} from 'antd';
import {DownOutlined, LoadingOutlined, LogoutOutlined, SmileOutlined} from '@ant-design/icons';
import userService from "../../service/userService";
import HomeTemplateContext from "../context/HomeTemplateContext";
import RechargeCreditModal from "./RechargeCreditModal";
import {getAuth,} from "firebase/auth";


const HomeTemplate = ({children}) => {

  const {
    isAuthAlready,
    currentUser,
    currentUserInfo,
    setCurrentUserInfo,
    setIsSignInModalOpen,
  } = useContext(HomeTemplateContext)

  const [isShowingOrderModal, setIsShowingOrderModal] = useState(false)

  const onClickLogin = async () => {
    setIsSignInModalOpen(true)
  }

  const onClickLogout = async () => {
    await getAuth().signOut()
    message.success('Logout success')
    setTimeout(() => {
      window.location.href = '/'
    })
  }

  const items = [{
    key: '1',
    label: (
      <a href={'/profile'}>
        My Profile
      </a>
    ),
  }, {
    key: '2',
    label: (
      <a onClick={onClickLogout}> Logout </a>
    ),
  }]

  const renderUserInfo = () => {
    if (isAuthAlready) {
      if (currentUser) {
        //已登录
        return (
          <div>
            <Dropdown
              menu={{
                items
              }}
              onOpenChange={(open) => {
                if (open) {
                  userService.myDetail((res) => {
                    setCurrentUserInfo(res.data)
                  }, (error) => {
                    message.error(error)
                  })
                }
              }}
              trigger={['click']}>
              <div
                style={{
                  border: "1px solid #f1f1f1",
                  padding: "6px 12px",
                  fontSize: '14px',
                  cursor: "pointer",
                }}>
                {currentUser.email} <DownOutlined style={{marginLeft: "4px", fontSize: "11px"}}/>
              </div>
            </Dropdown>
          </div>
        );
      } else {
        //未登录
        return (
          <div>
            <Button type={"primary"} onClick={onClickLogin}>Sign In</Button>
          </div>
        );
      }
    } else {
      return (
        <div>
          <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>
        </div>
      )
    }
  }

  return (
    <div
      className={"flex flex-col h-screen w-screen"}>
      <div style={{
        top: 0,
        zIndex: 1,
        width: '100%',
        display: "flex",
        height: '44px',
        background: "white",
        borderBottom: '1px solid #f1f1f1',
      }}>
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "white",
          height: '100%',
        }}>
          <p style={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "#34c567",
            marginLeft: "32px",
          }}
          >Pepper Reader</p>
        </div>
        <Menu
          theme="light"
          mode="horizontal"
          style={{
            flexGrow: 1,
        }}>
          <Menu.Item key="Home">
            <Link to="/home">Home</Link>
          </Menu.Item>
          <Menu.Item key="MyPapper">
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item>
        </Menu>
        <div className={"flex items-center mr-4"}>
        {/*<Button*/}
        {/*  className={" bg-[#5A65EA] text-white"}*/}
        {/*  onClick={()=>{*/}
        {/*    window.open(`${process.env.REACT_APP_DISCORD_URL}`, '_blank')*/}
        {/*  }}*/}
        {/*>Join Discord</Button>*/}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "white",
            height: '100%',
            paddingRight: "32px",
          }}>
          {renderUserInfo()}
        </div>
      </div>
      <div>
        {children}
      </div>

      <RechargeCreditModal
        open={isShowingOrderModal}
        onOk={() => {
          setIsShowingOrderModal(false)
        }}
        onCancel={() => {
          setIsShowingOrderModal(false)
        }}
      />
    </div>
  )
}
export default HomeTemplate
