import {useEffect} from "react";
import pdfService from "../../service/pdfService";

const SuccessPage = () => {
  //get params session_id from url
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get('session_id');
  console.log(sessionId);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await pdfService.paymentSuccess(sessionId)
        setTimeout(() => {
          if (res != null && res.data != null && res.data.redirectUrl != null) {
            window.location.href = res.data.redirectUrl
          }
        }, 3000)
      } catch (e) {
        console.error(e)
      }
    }

    fetchData()
  }, []);

  return (
    <div className={"flex flex-col justify-center"}>
      <div className={"flex justify-center"}>
        <h1>Payment Successful</h1>
      </div>
      <div className={"flex justify-center"}>
        <p>Redirecting in 3 seconds...</p>
      </div>
    </div>
  )
}
export default SuccessPage