export default class LanguageService {
  static convertTargetLang2Name(targetLang) {
    if (targetLang === 'zh') {
      targetLang = 'zh-CN'
    }

    const languages = this.getSupportedLanguages()
    for (let i = 0; i < languages.length; i++) {
      if (languages[i].language === targetLang) {
        return languages[i].name
      }
    }
  }


  static getSupportedLanguages() {
    return [
      {
        "language": "bg",
        "name": "Bulgarian",
        "supports_formality": false
      },
      {
        "language": "cs",
        "name": "Czech",
        "supports_formality": false
      },
      {
        "language": "da",
        "name": "Danish",
        "supports_formality": false
      },
      {
        "language": "de",
        "name": "German",
        "supports_formality": true
      },
      {
        "language": "el",
        "name": "Greek",
        "supports_formality": false
      },
      {
        "language": "en-GB",
        "name": "English (British)",
        "supports_formality": false
      },
      {
        "language": "en-US",
        "name": "English (American)",
        "supports_formality": false
      },
      {
        "language": "es",
        "name": "Spanish",
        "supports_formality": true
      },
      {
        "language": "et",
        "name": "Estonian",
        "supports_formality": false
      },
      {
        "language": "fi",
        "name": "Finnish",
        "supports_formality": false
      },
      {
        "language": "fr",
        "name": "French",
        "supports_formality": true
      },
      {
        "language": "hu",
        "name": "Hungarian",
        "supports_formality": false
      },
      {
        "language": "id",
        "name": "Indonesian",
        "supports_formality": false
      },
      {
        "language": "it",
        "name": "Italian",
        "supports_formality": true
      },
      {
        "language": "ja",
        "name": "Japanese",
        "supports_formality": true
      },
      {
        "language": "ko",
        "name": "Korean",
        "supports_formality": false
      },
      {
        "language": "lt",
        "name": "Lithuanian",
        "supports_formality": false
      },
      {
        "language": "lv",
        "name": "Latvian",
        "supports_formality": false
      },
      {
        "language": "nb",
        "name": "Norwegian (Bokmål)",
        "supports_formality": false
      },
      {
        "language": "nl",
        "name": "Dutch",
        "supports_formality": true
      },
      {
        "language": "pl",
        "name": "Polish",
        "supports_formality": true
      },
      {
        "language": "pt-BR",
        "name": "Portuguese (Brazilian)",
        "supports_formality": true
      },
      {
        "language": "pt-PT",
        "name": "Portuguese (European)",
        "supports_formality": true
      },
      {
        "language": "ro",
        "name": "Romanian",
        "supports_formality": false
      },
      {
        "language": "ru",
        "name": "Russian",
        "supports_formality": true
      },
      {
        "language": "sk",
        "name": "Slovak",
        "supports_formality": false
      },
      {
        "language": "sl",
        "name": "Slovenian",
        "supports_formality": false
      },
      {
        "language": "sv",
        "name": "Swedish",
        "supports_formality": false
      },
      {
        "language": "tr",
        "name": "Turkish",
        "supports_formality": false
      },
      {
        "language": "uk",
        "name": "Ukrainian",
        "supports_formality": false
      },
      {
        "language": "zh-CN",
        "name": "Chinese (simplified)",
        "supports_formality": false
      },
      {
        "language": "zh-TW",
        "name": "Chinese (Traditional)",
        "supports_formality": false
      },
    ]
  }

}
