import {Select} from "antd";
import languageService from "../../service/languageService";
import React, {useState} from "react";

const LanguageSelect = ({
                          className,
                          onChange
                        }) => {
  const defaultLanguage = localStorage.getItem('defaultLanguage') || navigator.language

  return (
    <Select
      defaultValue={defaultLanguage}
      className={"w-[180px]"}
      onChange={(e) => {
        localStorage.setItem('defaultLanguage', e)
        onChange(e)
      }}>
      {
        languageService.getSupportedLanguages().map((item) => {
          return (
            <Select.Option
              key={item.language}
              value={item.language}>{item.name}
            </Select.Option>
          )
        })
      }
    </Select>
  )
}

export default LanguageSelect
