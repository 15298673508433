import React, {useEffect, useRef, useState} from "react";
import {SoundOutlined} from "@ant-design/icons";

const MP3Player = ({
                     className,
                     src,
                     autoPlay,
                     onEnded,
                   }) => {
  const audioRef = useRef(null);
  const handlePlay = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime = 0;
      audio.play();
    }
  }

  return (
    <div className={className}>
      <audio
        autoPlay={autoPlay}
        src={src}
        ref={audioRef}
        onEnded={()=>{
          onEnded(src)
        }}
      />
      <SoundOutlined onClick={handlePlay}/>
    </div>
  );
};

export default MP3Player
