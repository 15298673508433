import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Spin, Tabs} from 'antd';
import PdfContext from "../context/PDFContext";
import pdfService from "../../service/pdfService";
import lookupService from "../../service/lookupService";
import {getAuth} from "firebase/auth";
import {LoadingOutlined, SoundOutlined} from "@ant-design/icons";
import {isWord} from "../../utils/ada";
import {isEmpty} from "lodash";
import MP3Player from "./MP3Player";
import LanguageSelect from "./LanguageSelect";
import userService from "../../service/userService";
import HomeTemplateContext from "../context/HomeTemplateContext";


const LookupComponent = ({
                           style,
                           visible,
                           selectedText,
                           onClickAddAnnotation,
                         }) => {
  const {
    setIsRechargeCreditModalOpen,
  } = useContext(HomeTemplateContext)


  const [currentTab, setCurrentTab] = useState('1')
  const [dictionary, setDictionary] = useState(null)
  const [dictionaryError, setDictionaryError] = useState('Loading...')
  const [wikipedia, setWikipedia] = useState(null)
  const [wikipediaError, setWikipediaError] = useState('Loading...')
  const [translationWords, setTranslationWords] = useState([])
  const [translationResult, setTranslationResult] = useState(null)
  const [targetLang, setTargetLang] = useState(localStorage.getItem('defaultLanguage') || navigator.language)

  const onChangeTabs = async (key) => {
    setCurrentTab(key)
    if (selectedText == null || selectedText === '') {
      return
    }
    switch (key) {
      case '1':
        try {
          const res = await lookupService.dictionary(selectedText)
          setDictionary(res.data)
        } catch (e) {
          setDictionaryError(e.msg)
        }
        break
      case '2':
        setTranslationResult(null)
        try {
          const result = await pdfService.translateByGoogle(selectedText, targetLang)
          setTranslationResult(result.data)
        } catch (e) {
          if (e.code === 708) {
            setIsRechargeCreditModalOpen(true)
          }
          console.error(e)
        }
        break
      case '3':
        try {
          await userService.checkCredit();
          setTranslationWords([])
          const idToken = await getAuth().currentUser.getIdToken()
          pdfService.translateStream(selectedText, targetLang, idToken, (newMessage) => {
            setTranslationWords((prevMsgs) => [...prevMsgs, newMessage]);
          }, (error) => {
            console.error("SSE error", error);
          })
        } catch (e) {
          if (e.code === 708) {
            setIsRechargeCreditModalOpen(true)
          }
          console.error(e)
        }

        break
      case '4':
        try {
          const res = await lookupService.wikiLookup(selectedText)
          setWikipedia(res.data)
        } catch (e) {
          setWikipediaError(e.msg)
        }
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (selectedText == null) {
      return
    }

    async function fetch() {
      if (isWord(selectedText)) {
        setCurrentTab('1')
        try {
          const res = await lookupService.dictionary(selectedText)
          setDictionary(res.data)
        } catch (e) {
          setDictionaryError(e.msg)
        }
      } else {
        setCurrentTab('2')
        setTranslationResult(null)
        try {
          const result = await pdfService.translateByGoogle(selectedText, targetLang)
          setTranslationResult(result.data)
        } catch (e) {
          console.error(e)
        }
      }
    }

    fetch()
  }, [selectedText]);


  const combineTranslationWords = () => {
    let content = ''
    translationWords.map((msg, index) => {
      if (msg.content != null) {
        content += msg.content
      }
    })
    return content
  }

  const renderOpenAITranslationWords = () => {
    const text = combineTranslationWords()
    if (text == null || text === '') {
      return (
        <div className={"mb-8"}>
          <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>
        </div>
      )
    } else {
      return (
        <div className={"mb-8"}>
          <p>{text}</p>
        </div>
      )
    }
  }

  const renderGoogleTranslationResult = () => {
    if (translationResult == null || translationResult === '') {
      return (
        <div className={"mb-8"}>
          <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>
        </div>
      )
    } else {
      return (
        <div className={"mb-8"}>
          <p>{translationResult}</p>
        </div>
      )
    }
  }
  const [lastAudio, setLastAudio] = useState(null)

  /**
   * iterate through phonetics and find a item that contains audio and text
   */
  const renderWordAndPhonetic = (dictionary) => {
    if (dictionary == null) {
      return null
    }

    let result = dictionary?.phonetics[0]
    dictionary?.phonetics?.forEach((item) => {
      if (!isEmpty(item.audio) && !isEmpty(item.text)) {
        result = item
      }
    })
    return (
      <div className={"flex"}>
        <span className={"text-black font-bold"}>{dictionary?.word}</span>
        <span className={"ml-2"}>{result?.text}</span>
        {!isEmpty(result?.audio) &&
          <MP3Player
            autoPlay={lastAudio !== result?.audio}
            className={"ml-2"}
            src={result?.audio}
            onEnded={(src) => {
              setLastAudio(src)
            }}
          />
        }
      </div>
    )
  }


  const formatDictionaryResult = (dictionary) => {
    if (dictionary == null) {
      return null
    }

    let result = dictionary?.phonetics[0]
    dictionary?.phonetics?.forEach((item) => {
      if (!isEmpty(item.audio) && !isEmpty(item.text)) {
        result = item
      }
    })
    return `${dictionary?.word} ${result?.text}`
  }

  if (!visible) {
    return null
  } else {
    return (
      <div
        style={style}>
        <Tabs
          onChange={onChangeTabs}
          size={"small"}
          defaultActiveKey={"1"}
          activeKey={currentTab}
          items={[
            {
              label: 'Dictionary',
              key: '1',
              children: (
                <div>
                  {selectedText == null && <div></div>}
                  {selectedText != null && <div>
                    {dictionary == null && <p>{dictionaryError}</p>}
                    {dictionary != null && <div>
                      {renderWordAndPhonetic(dictionary)}
                      <ul>
                        {dictionary.meanings.map((item, index) => {
                          return (
                            <li key={index}>
                              <span className={"font-bold"}>{item.partOfSpeech}</span>.<span
                              className={'ml-1'}>{item.definitions[0].definition}</span>
                            </li>
                          )
                        })}
                      </ul>
                      <Button onClick={() => {
                        onClickAddAnnotation(formatDictionaryResult(dictionary))
                      }}>Add Annotation</Button>
                    </div>}
                  </div>}
                </div>
              )
            },
            {
              label: 'Google',
              key: '2',
              children: (
                <div>
                  {selectedText == null && <div></div>}
                  {selectedText != null && <div>
                    <p className={"bg-black p-4 text-white rounded"}
                    >{selectedText}</p>
                    <div style={{display: "flex", alignItems: 'center'}}>
                      <p>Translation</p>
                      <div style={{height: '1px', width: '100%', backgroundColor: 'black', marginLeft: '4px'}}></div>
                      <LanguageSelect
                        onChange={(value) => {
                          setTargetLang(value)
                        }}
                      />
                    </div>
                    {renderGoogleTranslationResult()}
                    <Button onClick={() => {
                      onClickAddAnnotation(translationResult)
                    }}>Add Annotation</Button>
                  </div>}
                </div>
              )
            },

            {
              label: 'OpenAI',
              key: '3',
              children: (
                <div>
                  {selectedText == null && <div></div>}
                  {selectedText != null && <div>
                    <p className={"bg-black p-4 text-white rounded"}
                    >{selectedText}</p>
                    <div style={{display: "flex", alignItems: 'center'}}>
                      <p>Translation</p>
                      <div style={{height: '1px', width: '100%', backgroundColor: 'black', marginLeft: '4px'}}></div>
                      <LanguageSelect
                        onChange={(value) => {
                          setTargetLang(value)
                        }}
                      />
                    </div>
                    {renderOpenAITranslationWords()}
                    <Button onClick={() => {
                      const text = combineTranslationWords()
                      onClickAddAnnotation(text)
                    }}>Add Annotation</Button>
                  </div>}
                </div>
              )
            },
            {
              label: 'Wikipedia',
              key: '4',
              children: (
                <div>
                  {selectedText == null && <div></div>}
                  {selectedText != null && <div>
                    {wikipedia == null && <p>{wikipediaError}</p>}
                    {wikipedia != null && <div>
                      <p>{wikipedia.description}</p>
                      <a href={wikipedia.content_urls.desktop.page}>{wikipedia.title}</a>
                    </div>}
                  </div>}
                </div>
              )
            }
          ]}/>
      </div>
    )
  }
}
export default LookupComponent;
