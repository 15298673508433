import React, {useContext, useEffect, useState} from 'react'
import {useRouteMatch} from "react-router-dom";
import pdfShareService from "../../service/pdfShareService";
import {Button, message} from "antd";
import HomeTemplateContext from "../context/HomeTemplateContext";

const ShareAcceptPage = () => {
  const match = useRouteMatch('/pdf/share/:id')
  const pdfShareId = match.params.id
  const {
    isAuthAlready,
    currentUser,
    setIsSignInModalOpen,
  } = useContext(HomeTemplateContext)

  const [preShareData, setPreShareData] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    async function init() {
      try {
        const res = await pdfShareService.preAccept(pdfShareId)
        setPreShareData(res.data)
      } catch (e) {
        setErrorMessage(e.msg)
      }
    }
    init()
  }, []);

  useEffect(() => {
    if (isAuthAlready && currentUser == null) {
      setIsSignInModalOpen(true)
    }
  }, [isAuthAlready, currentUser]);

  if (isAuthAlready) {
    if (currentUser == null) {
      return null
    } else {
      return (
        <div>
          {errorMessage && <div className="flex justify-center mt-24">
            <span className={"text-red-500"}> {errorMessage} </span>
          </div>}
          {preShareData && <div>
            <div className={"flex justify-center mt-24"}>
              <span className={"text-[30px]"}>{preShareData.title}</span>
            </div>
            <div className={"flex justify-center mt-2"}>
              <span className={"text-sm text-gray-500"}>{preShareData.description}</span>
            </div>
            <div className={"flex justify-center mt-12"}>
              <span>{preShareData.fileName}</span>
            </div>

            <div className={"flex justify-center mt-2"}>
              <Button
                className={"w-[200px]"}
                type="primary"
                onClick={async () => {
                  try {
                    const res = await pdfShareService.accept(pdfShareId)
                    message.success('Accept success')
                    setTimeout(() => {
                      window.location.href = `/dashboard/${res.data.pdfId}`
                    }, 1000)
                  } catch (e) {
                    console.log('e', e)
                    message.error(e.msg)
                  }
                }}
              >Accept</Button>
            </div>
          </div>}
        </div>
      );
    }
  } else {
    return null
  }

}

export default ShareAcceptPage
