import React, {useContext, useEffect, useState} from "react";
import pdfPageTranslateService from "../../service/pdfPageTranslateService";
import {Button, Input, Popconfirm, Popover, Select, Tooltip} from "antd";
import {DownloadOutlined, LeftOutlined, ReloadOutlined, RightOutlined, SettingOutlined} from "@ant-design/icons";
import LanguageSelect from "./LanguageSelect";
import {getAuth} from "firebase/auth";
import pdfService from "../../service/pdfService";
import userService from "../../service/userService";
import HomeTemplateContext from "../context/HomeTemplateContext";

const PageTranslationView = ({
                               pdf,
                               visible,
                               currentPageIndex,
                               setCurrentPageIndex,
                               onClickPageTranslate,
                               pageTranslationEntity,
                             }) => {

  const {
    setIsRechargeCreditModalOpen,
  } = useContext(HomeTemplateContext)
  const defaultLanguage = localStorage.getItem('defaultLanguage') || navigator.language
  const defaultTranslator = localStorage.getItem('defaultTranslator') || 'openAI'
  const [pageTranslationMap, setPageTranslationMap] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [translator, setTranslator] = useState(defaultTranslator)
  const [modalSelectValue, setModalSelectValue] = useState(localStorage.getItem('defaultModal') || 'gpt-4')

  const pageTrans = async (pageTranslationEntity) => {
    const {
      text,
      pageIndex
    } = pageTranslationEntity

    try {
      await userService.checkCredit();
      if (translator === 'google') {
        setIsLoading(true)
        await pdfPageTranslateService.createByGoogle(pdf.id, defaultLanguage, pageIndex, text)
        await findAll(pdf)
        setIsLoading(false)
      } else if (translator === 'openAI') {
        const streamData = []
        const idToken = await getAuth().currentUser.getIdToken()
        pdfPageTranslateService.createByOpenAI(idToken, pdf.id, defaultLanguage, pageIndex, text, modalSelectValue, (newMessage) => {
          streamData.push(newMessage)
          let result = ''
          streamData.map((msg, index) => {
            if (msg.content != null) {
              result += msg.content
            }
          })
          setPageTranslationMap({
            ...pageTranslationMap,
            [pageIndex]: result
          })
        }, (error) => {
          console.error("SSE error", error);
        })
      }
    } catch (e) {
      if (e.code === 708) {
        setIsRechargeCreditModalOpen(true)
      }
      console.error(e)
    }
  }

  const findAll = async (pdf) => {
    const res = await pdfPageTranslateService.findAll(pdf.id, defaultLanguage)
    const result = {}
    res.data.map((item) => {
      result[item.pageIndex] = item.text
    })
    setPageTranslationMap(result)
  }
  /**
   * iterate pageTranslateMap
   * @return {Promise<void>}
   */
  const onClickDownload = async () => {
    let content = ''
    for (let key in pageTranslationMap) {
      content += pageTranslationMap[key] + '\n'
    }

    const element = document.createElement("a");
    const file = new Blob([content], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = "chat.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  useEffect(() => {
    if (!pageTranslationEntity) {
      return
    }
    pageTrans(pageTranslationEntity)
  }, [pageTranslationEntity]);


  useEffect(() => {
    findAll(pdf)
  }, [pdf])

  if (!visible) {
    return null
  } else {
    return (
      <div className={"flex flex-col"}
           style={{
             width: '500px',
             borderLeft: '1px solid #e8e8e8',
           }}
      >
        <div className={"flex flex-row justify-between h-[44px] items-center"}
             style={{
               borderBottom: '1px solid #e8e8e8',
             }}>
          <div className={"flex items-center"}>
            <span className={"ml-2"}>Page Translation</span>
            <div className={"flex items-center ml-5 text-[13px]"}>
              <span className={"mr-1"}>Page</span>
              <Button
                size={"small"}
                onClick={() => {
                  if (currentPageIndex === 0) {
                    return
                  }
                  setCurrentPageIndex(currentPageIndex - 1)
                }}
              ><LeftOutlined/></Button>
              <Input
                value={currentPageIndex + 1}
                size={"small"}
                rootClassName={"w-12 text-center "}/>
              <Button
                onClick={() => {
                  if (currentPageIndex === pdf?.totalPageNumber - 1) {
                    return
                  }
                  setCurrentPageIndex(currentPageIndex + 1)
                }}
                size={"small"}
              ><RightOutlined/></Button>
              <span className={"ml-1"}> of {pdf?.totalPageNumber}</span>
            </div>
          </div>

          <div className={"flex"}>


            <Tooltip
              title={"Download"}
              placement={"bottom"}>

              <DownloadOutlined
                className={"mr-4 text-[20px]"}
                onClick={onClickDownload}
              />
            </Tooltip>

            <Tooltip
              placement={"bottom"}
              title={"Re-translate"}>
              <Popconfirm
                title={"Do you want to re-translate?"}
                onConfirm={() => {
                  onClickPageTranslate(currentPageIndex)
                }}>
                <ReloadOutlined className={"mr-4 text-[20px]"}/>
              </Popconfirm>
            </Tooltip>
            <Popover
              title={"Page Translate Setting"}
              content={
                <div className={"w-[400px]"}>
                  <div className={"flex justify-between items-center"}>
                    <span>Translator:</span>
                    <Select
                      defaultValue={defaultTranslator}
                      className={"w-[100px]"}
                      onChange={(value) => {
                        localStorage.setItem('defaultTranslator', value)
                        setTranslator(value)
                      }}
                    >
                      <Select.Option value="openAI">OpenAI</Select.Option>
                      <Select.Option value="google">Google</Select.Option>
                    </Select>
                  </div>

                  {translator === 'openAI' &&
                    <div className={"flex justify-between items-center mt-2"}>
                      <span>Default Model:</span>
                      <Select
                        className={"ml-2 w-[100px]"}
                        defaultValue={modalSelectValue}
                        bordered={true}
                        onChange={(e) => {
                          localStorage.setItem('defaultModal', e)
                          setModalSelectValue(e)
                        }}
                        options={[
                          {
                            value: 'gpt-4',
                            label: 'GPT4',
                          },
                          {
                            value: 'gpt-3.5-turbo-16k',
                            label: 'GPT3.5',
                          },
                        ]}
                      />
                    </div>}

                  <div className={"flex justify-between mt-2"}>
                    <span>Target Language:</span>
                    <LanguageSelect
                      onChange={(value) => {
                        // setTargetLang(value)
                      }}/>
                  </div>
                </div>}>

              <SettingOutlined className={"text-[20px] mr-4"}/>
            </Popover>
          </div>
        </div>

        <div className={"flex flex-col overflow-y-scroll"}
             style={{
               backgroundColor: '#f4f7f9',
               height: 'calc(100vh - 44px)',
             }}>
          {pageTranslationMap.hasOwnProperty(currentPageIndex) === false &&
            <div className={"flex justify-center"}>
              <Button
                loading={isLoading}
                className={"mt-4"}
                onClick={async () => {
                  onClickPageTranslate(currentPageIndex)
                }}>Translate Page {currentPageIndex + 1}</Button>

            </div>}
          {pageTranslationMap.hasOwnProperty(currentPageIndex) === true &&
            <div className={"flex justify-center"}>
              <pre
                className={"px-4 py-2"}
                style={{
                  border: '1px solid #e8e8e8',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                  maxWidth: '430px',
                  minWidth: '430px',
                  textAlign: 'left',
                  fontFamily: 'sans-serif',
                  backgroundColor: 'white',
                  boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                  fontSize: '14px',
                }}>{pageTranslationMap[currentPageIndex]}</pre>
            </div>}
        </div>
      </div>
    )
  }
}

export default PageTranslationView;
