import {Table} from "antd";
import React, {useEffect, useState} from "react";
import adminUserService from "../../service/admin/adminUserService";
import moment from "moment/moment";
import {message} from "antd";


const UserListView = ({}) => {

  const [userList, setUserList] = useState([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const findList = async (page, pageSize) => {
    try {
      const res = await adminUserService.findList(page, pageSize)
      setUserList(res.data.list)
      setTotal(res.data.total)
    } catch (e) {
      message.error(e.message)
    }
  }

  useEffect(() => {
    findList(page, pageSize)
  }, [])

  return (
    <div className={"flex overflow-y-scroll h-screen "}>
      <Table
        pagination={{
          onChange: async (page, pageSize) => {
            await findList(page, pageSize)
          },
          pageSize: pageSize,
          total: total,
        }}
        dataSource={userList}
        rowKey={(record) => {
          return record.id
        }}
        columns={[
          {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            render: (_, record) => (
              <span>{record.id}</span>
            ),
          },
          {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
            render: (_, record) => (
              <span>{record.email}</span>
            ),
          },
          {
            title: 'Display Name',
            dataIndex: 'displayName',
            key: 'displayName',
            render: (_, record) => (
              <span>{record.displayName}</span>
            ),
          },
          {
            title: 'Document Count',
            dataIndex: 'documentCount',
            key: 'documentCount',
            render: (_, record) => (
              <span>{record.documentCount}</span>
            ),
          },
          {
            title: 'Credit',
            dataIndex: 'credit',
            key: 'credit',
            render: (_, record) => (
              <span>{record?.account?.credit}</span>
            ),
          },
          {
            title: 'Last Login',
            dataIndex: 'updated',
            key: 'updated',
            render: (_, record) => (
              <span>{moment(record.updated).format('YYYY-MM-DD HH:mm:ss')}</span>
            ),
          }, {

            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            render: (_, record) => (
              <span>{moment(record.created).format('YYYY-MM-DD HH:mm:ss')}</span>
            ),
          }

        ]}
      />
    </div>
  )
}

export default UserListView;
