import axios from 'axios';
import {getAuth} from "firebase/auth";
import {message} from "antd";

const BASE_URL = process.env.REACT_APP_API_URL
const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 30000
});

// 请求拦截器，自动添加 token 到 headers 中
instance.interceptors.request.use(async function (config) {
  await getAuth().authStateReady()
  if (getAuth().currentUser != null) {
    const idToken = await getAuth().currentUser.getIdToken()
    //console.log('idToken', idToken)
    config.headers.idToken = idToken
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

export default class HttpRequest {
  static post(options) {
    return new Promise((resolve, reject) => {
      instance.post(options.url, options.params)
        .then((response) => {
          if (response.status === 200) {
            let res = response.data;
            if (res.code === 200) {
              resolve(res);
            } else {
              this.handleServerError(res.code, res.msg)
              reject(response.data);
            }
          } else {
            reject(response.data);
          }
        })
        .catch((error) => {
          //处理http错误
          this.handleHttpError(error.response.status)
          reject(error);
        });
    })
  };

  static get(options) {
    return new Promise((resolve, reject) => {
      instance.get(options.url)
        .then((response) => {
          if (response.status === 200) {
            let res = response.data;
            if (res.code === 200) {
              resolve(res);
            } else {
              this.handleServerError(res.code, res.msg)
              reject(response.data);
            }
          } else {
            // console.log('!200' + response);
            reject(response.data);
          }
        })
        .catch((error) => {
          //处理http错误
          console.error(error)
          this.handleHttpError(error.response.status)
          reject(error);
        });
    })
  };

  static handleHttpError(status) {
    console.log('handle http error', status)
    switch (status) {
      case 401:
        break;
      case 403:
        break;
      default:
        break;
    }
  }

  /**
   * 通用错误提示
   * @param code
   * @param msg
   */
  static handleServerError(code, msg) {
    if (code >= 600 && code <= 900) {
      message.error(msg)
    }
  }
}
