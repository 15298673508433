import httpRequest from '../utils/httpRequest';

export default class PDFShareService {

  static create = async (pdfId, shareToEmailList) => {
    return httpRequest.post({
      url: `/api/pdf/share/create`,
      params: {
        pdfId,
        shareToEmailList,
      }
    })
  }
  static getByPdfId = async (pdfId) => {
    return httpRequest.get({
      url: `/api/pdf/share/getByPdfId?pdfId=${pdfId}`,
    })
  }

  static addEmail = async (pdfId, email) => {
    return httpRequest.post({
      url: `/api/pdf/share/addEmail`,
      params: {
        pdfId,
        email,
      }
    })
  }
  static removeEmail = async (pdfId, email) => {
    return httpRequest.post({
      url: `/api/pdf/share/removeEmail`,
      params: {
        pdfId,
        email,
      }
    })
  }

  static preAccept = async (pdfShareId) => {
    return httpRequest.get({
      url: `/api/pdf/share/preAccept?pdfShareId=${pdfShareId}`,
    })
  }

  static accept = async (pdfShareId) => {
    return httpRequest.post({
      url: `/api/pdf/share/accept`,
      params: {
        pdfShareId,
      }
    })
  }


}