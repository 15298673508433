import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Input, message, Popconfirm, Popover, Select, Space, Tooltip} from 'antd';
import pdfService from "../../service/pdfService";
import userService from "../../service/userService";
import {
  ClearOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  SendOutlined, SettingOutlined
} from "@ant-design/icons";
import {getAuth} from "firebase/auth";
import HomeTemplateContext from "../context/HomeTemplateContext";
import LanguageSelect from "./LanguageSelect";


const ChatComponent = ({
                         pdf,
                         chatInputValue,
                         visible
                       }) => {
  const {
    setIsRechargeCreditModalOpen,
  } = useContext(HomeTemplateContext)

  const {
    id,
    charsCount,
    tokenCount,
    defaultModal,
  } = pdf

  const [localMessages, setLocalMessages] = useState([])
  const [enableSendMessage, setEnableSendMessage] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const messagesEndRef = useRef(null)
  const [modalSelectValue, setModalSelectValue] = useState(localStorage.getItem('defaultModal') || defaultModal)
  const defaultLanguage = localStorage.getItem('defaultLanguage') || navigator.language


  /**
   * 点击发送按钮
   */
  const onClickSend = async (_inputValue) => {
    try {
      await userService.checkCredit();
      //setIsSending(true)
      const newLocalMessages = [...localMessages, {
        role: 'user',
        content: _inputValue,
      }, {
        role: 'assistant',
        content: 'Loading...',
      }]
      setLocalMessages(newLocalMessages)
      //发送到后端
      setInputValue('')
      const streamData = []
      const idToken = await getAuth().currentUser.getIdToken()
      pdfService.sendMessageStream(id, _inputValue, modalSelectValue, idToken, (newMessage) => {
        streamData.push(newMessage)
        const content = combineStreamData2(streamData)
        const lastMessage = newLocalMessages[newLocalMessages.length - 1]
        lastMessage.content = content
        setLocalMessages([...newLocalMessages])
      }, (error) => {
        console.error("SSE error", error);
      })
    } catch (e) {
      if (e.code === 708) {
        setIsRechargeCreditModalOpen(true)
      }
      console.error(e)
    }

  }

  const combineStreamData2 = (streamData) => {
    let content = ''
    streamData.map((msg, index) => {
      if (msg.content != null) {
        content += msg.content
      }
    })
    return content
  }

  const getAllMessages = (id) => {
    // setLocalMessages([])
    pdfService.getMessages(id, (res) => {
      setLocalMessages(res.data.messages)
      setEnableSendMessage(res.data.enableSendMessage)
      //check if there is no message, then send a welcome message
      if (res.data.messages.length === 0) {
        // appendWelcomeMessage()
        summarize(id, defaultLanguage)
      }
    }, (error) => {
      console.log('error', error)
    })
  }

  const appendWelcomeMessage = () => {
    const appendMessage = {
      role: 'assistant',
      content: 'Welcome to PepperReader.com! This website is designed to help you read your PDF documents Efficiently and intelligently. You can chat with me about the content of your document or translate the entire file into your preferred language using either Google Cloud Translate or DeepL Translate. Additionally, you have the option to highlight, annotate, or make notes within the document itself.\n\n' +
        'Now, feel free to ask me any questions about the document, such as:\n' +
        '1. What is the main argument presented in this paper?\n' +
        '2. Could you provide more information on this specific topic?\n' +
        '3. Can you explain the methodology used in this research?\n'
    }
    setLocalMessages([appendMessage])
  }


  const summarize = async (pdfId, lang) => {
    try {
      await userService.checkCredit();
      const newLocalMessages = [...localMessages, {
        role: 'assistant',
        content: 'Loading...',
      }]
      setLocalMessages(newLocalMessages)
      const streamData = []
      const idToken = await getAuth().currentUser.getIdToken()
      pdfService.summarize(pdfId, lang, idToken, (newMessage) => {
        streamData.push(newMessage)
        const content = combineStreamData2(streamData)
        const lastMessage = newLocalMessages[newLocalMessages.length - 1]
        lastMessage.content = content
        setLocalMessages([...newLocalMessages])
      }, (error) => {
        console.error("SSE error", error);
      })
    } catch (e) {
      if (e.code === 708) {
        setIsRechargeCreditModalOpen(true)
      }
      console.error(e)
    }

  }
  /**
   * format localMessage to txt file and download for user
   */
  const onClickDownload = () => {
    const content = localMessages.map((item) => {
      return `${item.role === 'user' ? 'You: ' : 'PepperReader: '}${item.content}`
    }).join('\n\n')
    const element = document.createElement("a");
    const file = new Blob([content], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = "chat.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  const onClickClear = async () => {
    try {
      const res = await pdfService.clearMessages(id)
      getAllMessages(id)
      message.success('Clear success')
    } catch (e) {
      message.error('Clear failed')
    }
  }

  /**
   * 首次加载时，获取所有的messages
   */
  useEffect(() => {
    if (!visible) {
      return
    }
    getAllMessages(id)
  }, [pdf])

  /**
   * 当localMessages变化时，自动滚动到底部
   */
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({behavior: "smooth"})
  }, [localMessages])


  /**
   * 当外部的chatInputValue变化时，自动填充到inputValue
   */
  useEffect(() => {
    if (chatInputValue == null) {
      return
    }
    setInputValue(`\"${chatInputValue}\" \n`)
  }, [chatInputValue]);


  /**
   * 当visible变化时，自动滚动到底部
   */
  useEffect(() => {
    if (visible) {
      messagesEndRef.current?.scrollIntoView()
    }
  }, [visible]);


  if (!visible) {
    return <div></div>
  } else {
    return (
      <div
        className={"flex flex-col"}
        style={{
          borderLeft: '1px solid #e8e8e8',
          width: '480px',
          height: '100vh' //如果不设置父布局的高度，那么子布局的 flex-grow 就不会生效
        }}
      >
        <div className={"flex justify-between items-center "}
             style={{
               height: '44px',
               minHeight: '44px',
               maxHeight: '44px',
               borderBottom: '1px solid #e8e8e8',
             }}>
          <div className={"text-[16px] px-2"}>Chat</div>
          <div className={"flex flex-row"}>

            <DownloadOutlined
              className={"mr-4 text-[20px]"}
              onClick={onClickDownload}
            />
            <Popconfirm
              onConfirm={onClickClear}
              title={"Are you sure to clear all messages?"}>
              <ClearOutlined
                className={"mr-4 text-[20px]"}
              />
            </Popconfirm>
            <Popover
              title={"Chat Setting"}
              content={
                <div className={"w-[400px]"}>

                  <div className={"flex justify-between"}>
                    <span>Context Tokens:</span>
                    <span>{tokenCount}</span>
                  </div>
                  <div className={"flex justify-between items-center mt-2"}>
                    <span>Default Model:</span>
                    <Select
                      className={"ml-2 w-[100px]"}
                      defaultValue={modalSelectValue}
                      bordered={true}
                      onChange={(e) => {
                        localStorage.setItem('defaultModal', e)
                        setModalSelectValue(e)
                      }}
                      options={[
                        {
                          value: 'gpt-4',
                          label: 'GPT4',
                        },
                        {
                          value: 'gpt-3.5-turbo-16k',
                          label: 'GPT3.5',
                        },
                      ]}
                    />
                  </div>
                  {/*<div className={"flex justify-between items-center mt-2"}>*/}
                  {/*  <span>Default Language:</span>*/}
                  {/*  <LanguageSelect*/}
                  {/*    onChange={(value) => {*/}
                  {/*      // setTargetLang(value)*/}
                  {/*    }}/>*/}
                  {/*</div>*/}
                </div>}>
              <SettingOutlined className={"text-[20px] mr-4"}/>
            </Popover>
          </div>
        </div>
        <div className={"flex-grow overflow-y-auto"}>
          {localMessages.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: `${item.role === 'user' ? 'row-reverse' : 'row'}`,
                  width: "100%",
                }}>
                    <pre style={{
                      padding: '10px',
                      whiteSpace: 'pre-wrap',
                      wordWrap: 'break-word',
                      marginLeft: `${item.role === 'user' ? '0px' : '10px'}`,
                      marginRight: `${item.role === 'user' ? '10px' : '10px'}`,
                      backgroundColor: `${item.role === 'user' ? '#1777ff' : '#fff'}`,
                      color: `${item.role === 'user' ? 'white' : 'black'}`,
                      maxWidth: '380px',
                      textAlign: 'left',
                      fontFamily: 'sans-serif',
                      fontSize: '14px',
                      border: '1px solid #e8e8e8',
                    }} className={"rounded"}>
                      {item.content}
                    </pre>
              </div>
            )
          })}
          <div ref={messagesEndRef}/>
        </div>

        <div className={"flex flex-row p-2  items-center"}>
          <Input.TextArea
            autoSize={{
              minRows: 1,
              maxRows: 4,
            }}
            placeholder="Ask any question about the document in any language..."
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onClickSend(inputValue);
              }
            }}
          />
          <SendOutlined
            className={"ml-2 text-[20px]"}
            onClick={() => {
              onClickSend(inputValue)
            }}
          />
        </div>
      </div>
    )
  }
}
export default ChatComponent;
