import {Button, message, Popover, Skeleton, Spin, Tooltip} from 'antd'
import pdfService from "../../service/pdfService";
import React, {useEffect, useState} from 'react';
import PdfContext from "../context/PDFContext";
import PdfViewerComponent from "../component/PdfViewerComponent";
import ChatComponent from "../component/ChatComponent";
import LookupComponent from "../component/LookupComponent";
import {
  LoadingOutlined,
  MessageOutlined,
  SearchOutlined,
  ShareAltOutlined,
  TranslationOutlined
} from "@ant-design/icons";
import SharePDFModal from "../component/SharePDFModal";
import AddTranslateDocumentComponent from "../component/AddTranslateDocumentComponent";
import ManageTranslateDocumentComponent from "../component/ManageTranslateDocumentComponent";
import PageTranslationView from "../component/PageTranslationView";


const IndexPage = ({
                     id
                   }) => {

  const [pdf, setPdf] = useState(null)
  const [pdfTranslation, setPdfTranslation] = useState(null)

  const [currentTab, setCurrentTab] = useState('1')

  const [selectedText, setSelectedText] = useState(null)
  /**
   * 聊天框的输入值
   */
  const [chatInputValue, setChatInputValue] = useState(null)
  const [isCreateAnnotation, setIsCreateAnnotation] = useState(false)

  const [translationAnnotation, setTranslationAnnotation] = useState(null)

  const [isChatVisible, setIsChatVisible] = useState(true)
  const [isLookupVisible, setIsLookupVisible] = useState(false)
  const [isPageTranslateVisible, setIsPageTranslateVisible] = useState(false)

  const [isButtonsVisible, setIsButtonsVisible] = useState(false)
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false)

  const [isSideBySideReadingMode, setIsSideBySideReadingMode] = useState(false)

  const [isShowingShareModal, setIsShowingShareModal] = useState(false)

  const [isAddDocTransModalOpen, setIsAddDocTransModalOpen] = useState(false)
  const [isManageDocTransModalOpen, setIsManageDocTransModalOpen] = useState(false)

  const [extractPageIndex, setExtractPageIndex] = useState(null)
  const [pageTranslationEntity, setPageTranslationEntity] = useState(null)
  const [currentPageIndex, setCurrentPageIndex] = useState(0)


  useEffect(() => {
    getById(id)
  }, [id])

  const getById = async (id) => {
    try {
      const res = await pdfService.getById(id)
      setPdf(res.data)
    } catch (e) {
      console.error(e)
    }
  }

  const exportXFDF = async (xfdfString) => {
    const result = await pdfService.exportAnnotationsAsync(id, xfdfString)
    console.log('result', result)
  }

  const exportInstantJSON = async (instantJSON) => {
    const result = await pdfService.exportInstantJSON(id, instantJSON)
    console.log('result', result)
  }


  if (pdf == null) {
    return (
      <div className={"bg-white p-10"}>
        <span className={""}>Loading...</span>
        <Skeleton
          className={""}
          active/>
      </div>
    )
  } else {
    return (
      <PdfContext.Provider value={{
        selectedText,
        setSelectedText,

        isCreateAnnotation,  //是否创建注释
        setIsCreateAnnotation,

        currentTab,
        setCurrentTab,

        isLookupVisible,
        setIsLookupVisible,

      }}>
        <div className={"flex flex-row"}>
          <div className={"flex-grow"}>
            <div className={"relative"}>
              <div className={"flex flex-row"}>
                <div className={"flex-1"}>
                  <PdfViewerComponent
                    className={"flex-1"}
                    pdf={pdf}
                    translationAnnotation={translationAnnotation}
                    onClickTranslate={(selectedText) => {
                      console.log('selectedText', selectedText.length)
                      if (selectedText.length > 1024) {
                        message.error('You selected text is too long, please try again.')
                        return
                      }
                      setSelectedText(selectedText)
                      setIsLookupVisible(true)
                    }}
                    onClickChat={(selectedText) => {
                      setChatInputValue(selectedText)
                      setIsChatVisible(true)
                    }}
                    onMouseLeftDown={() => {
                      setIsLookupVisible(false)
                    }}
                    onExportXFDF={async (xfdfString) => {
                      await exportXFDF(xfdfString)
                    }}
                    onExportInstantJSON={async (instantJSON) => {
                      // console.log('instantJSON', instantJSON)
                      await exportInstantJSON(instantJSON)
                    }}

                    extractPageIndex={extractPageIndex}
                    onExtractPageIndexSuccess={async (text, pageIndex) => {
                      console.log('onExtractSuccessByPageIndex', text, pageIndex)
                      setPageTranslationEntity({
                        text: text,
                        pageIndex: pageIndex,
                      })
                    }}

                    onCurrentPageIndexChange={(pageIndex) => {
                      setCurrentPageIndex(pageIndex)
                    }}

                    onDocumentLoaded={() => {
                      setIsDocumentLoaded(true)
                      setIsButtonsVisible(true)
                    }}
                  />
                </div>


                {isSideBySideReadingMode && (
                  <div className={"flex-1"}>
                    <PdfViewerComponent
                      className={"flex-1"}
                      pdf={pdfTranslation}
                      translationAnnotation={translationAnnotation}
                      onClickTranslate={(selectedText) => {
                        setSelectedText(selectedText)
                        setIsLookupVisible(true)
                      }}
                      onClickChat={(selectedText) => {
                        setChatInputValue(selectedText)
                        setIsChatVisible(true)
                      }}
                      onMouseLeftDown={() => {
                      }}
                      onExportXFDF={async (xfdfString) => {
                        // await exportXFDF(xfdfString)
                      }}
                      onDocumentLoaded={() => {

                      }}
                    />
                  </div>
                )}
              </div>


              {!isDocumentLoaded && (
                <div style={{
                  position: 'absolute',
                  top: '60px',
                  left: '50%',
                }}>
                  <Spin indicator={<LoadingOutlined style={{fontSize: 24}}/>}/>
                </div>
              )}

              {isButtonsVisible && (
                <div style={{
                  position: 'absolute',
                  left: '20px',
                  bottom: '0px',
                }}>
                  <div className={"flex flex-col"}>
                    <Tooltip
                      placement={"right"}
                      title={"Chat with AI"}>
                      <Button
                        size={"large"}
                        className={"mb-4"}
                        shape={"circle"}
                        type={isChatVisible ? 'primary' : 'default'}
                        icon={<MessageOutlined/>}
                        onClick={() => {
                          setIsChatVisible(!isChatVisible)
                          setIsPageTranslateVisible(false)
                        }}/>
                    </Tooltip>

                    {/*<Popover*/}
                    {/*  placement={"right"}*/}
                    {/*  content={<div>*/}
                    {/*    <Button*/}
                    {/*      onClick={() => {*/}
                    {/*        setIsManageDocTransModalOpen(true)*/}
                    {/*      }}*/}
                    {/*    >Manage Translated Document</Button>*/}
                    {/*  </div>}*/}
                    {/*>*/}

                    {/*  <Button*/}
                    {/*    size={"large"}*/}
                    {/*    className={"mb-4"}*/}
                    {/*    shape={"circle"}*/}
                    {/*    type={isSideBySideReadingMode ? 'primary' : 'default'}*/}
                    {/*    icon={<TranslationOutlined/>}*/}
                    {/*    onClick={() => {*/}
                    {/*      // setIsFullTextVisible(!isFullTextVisible)*/}
                    {/*      if (pdf.pdfTranslations == null || pdf.pdfTranslations.length === 0) {*/}
                    {/*        setIsAddDocTransModalOpen(true)*/}
                    {/*      } else {*/}
                    {/*        setIsSideBySideReadingMode(!isSideBySideReadingMode)*/}
                    {/*        setPdfTranslation(pdf.pdfTranslations[0])*/}
                    {/*      }*/}
                    {/*    }}></Button>*/}

                    {/*</Popover>*/}

                    <Tooltip
                      title={"Page Translation"}
                      placement={"right"}>

                      <Button
                        size={"large"}
                        className={"mb-4"}
                        shape={"circle"}
                        type={isPageTranslateVisible ? 'primary' : 'default'}
                        icon={<TranslationOutlined/>}
                        onClick={() => {
                          setIsChatVisible(false)
                          setIsPageTranslateVisible(!isPageTranslateVisible)
                        }}></Button>

                    </Tooltip>

                    <Tooltip
                      placement={"right"}
                      title={"Lookup"}>
                      <Button
                        size={"large"}
                        className={"mb-4"}
                        shape={"circle"}
                        icon={<SearchOutlined/>}
                        type={isLookupVisible ? 'primary' : 'default'}
                        onClick={() => {
                          //setSelectedText(null)
                          setIsLookupVisible(!isLookupVisible)
                        }}/>
                    </Tooltip>
                    {/*<Tooltip*/}
                    {/*  placement={"right"}*/}
                    {/*  title={"Share PDF"}>*/}
                    {/*  <Button*/}
                    {/*    size={"large"}*/}
                    {/*    className={"mb-4"}*/}
                    {/*    shape={"circle"}*/}
                    {/*    icon={<ShareAltOutlined/>}*/}
                    {/*    // type={isMindVisible ? 'primary' : 'default'}*/}
                    {/*    onClick={() => {*/}
                    {/*      // setIsMindVisible(!isMindVisible)*/}
                    {/*      setIsShowingShareModal(true)*/}
                    {/*    }}/>*/}
                    {/*</Tooltip>*/}
                  </div>
                </div>)}

              <LookupComponent
                visible={isLookupVisible}
                selectedText={selectedText}
                style={{
                  position: 'absolute',
                  background: 'white',
                  left: '80px',
                  bottom: '20px',
                  maxWidth: '500px',
                  padding: '0px 10px 10px 10px',
                  border: '1px solid grey',
                  borderRadius: '8px',
                  flexDirection: 'column',
                  display: 'flex',
                  minWidth: '200px',
                  minHeight: '200px',
                }}
                onClickAddAnnotation={(text) => {
                  setTranslationAnnotation(text)
                }}
              />

            </div>
          </div>

          <PageTranslationView
            pdf={pdf}
            visible={isPageTranslateVisible}
            currentPageIndex={currentPageIndex}
            setCurrentPageIndex={setCurrentPageIndex}
            pageTranslationEntity={pageTranslationEntity}
            onClickPageTranslate={(pageIndex) => {
              console.log('onClickPageTranslate', pageIndex)
              setExtractPageIndex(pageIndex)
            }}

          />

          <ChatComponent
            pdf={pdf}
            visible={isChatVisible}
            chatInputValue={chatInputValue}
          />


        </div>

        <SharePDFModal
          pdfId={id}
          open={isShowingShareModal}
          onOk={() => {
            setIsShowingShareModal(false)
          }}
          onCancel={() => {
            setIsShowingShareModal(false)
          }}
        />

        <AddTranslateDocumentComponent
          id={id}
          open={isAddDocTransModalOpen}
          onOk={() => {
            setIsAddDocTransModalOpen(false)
          }}
          onCancel={() => {
            setIsAddDocTransModalOpen(false)
          }}
        />
        <ManageTranslateDocumentComponent
          id={id}
          open={isManageDocTransModalOpen}
          onOk={() => {
            setIsManageDocTransModalOpen(false)
          }}
          onCancel={() => {
            setIsManageDocTransModalOpen(false)
          }}
        />
      </PdfContext.Provider>
    );
  }
}

export default IndexPage;
