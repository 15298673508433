import React, {useContext, useEffect, useState} from 'react'
import howToUse from '../../assets/movie/howtouse.mov'
import DeepLLogo from '../../assets/img/DeepL_logo.svg.png'
import GoogleCloudLogo from '../../assets/img/google_cloud-ar21.svg'
import OpenAILogo from '../../assets/img/OpenAI_Logo.svg.png'
import {Button, Input, message, Upload, Space, Table, Card} from "antd";
import UploadFileComponent from "../component/UploadFileComponent";
import pdfService from "../../service/pdfService";
import HomeTemplateContext from "../context/HomeTemplateContext";
import {BankOutlined, ExperimentOutlined, IdcardOutlined} from "@ant-design/icons";
import CreditIcon from "../component/CreditIcon";
import celebration from '../../assets/img/celebration.png'

const HomePage = () => {

  const {
    isAuthAlready,
    currentUser,
    setIsRechargeCreditModalOpen,
    setIsSignInModalOpen,
  } = useContext(HomeTemplateContext)

  const onClickStartDemo = async () => {
    try {
      const res = await pdfService.startDemo()
      const pdfId = res.data.pdfId
      window.location.href = '/dashboard/' + pdfId
    } catch (e) {
      message.error(e.msg)
    }
  }

  /**
   * Redirect to dashboard if user is already logged in
   */
  useEffect(() => {
    if (currentUser != null && window.location.href === process.env.REACT_APP_PUBLIC_URL) {
      window.location.href = '/dashboard';
    }
  }, [currentUser]);

  return (
    <div className="flex flex-col justify-center bg-white">
      <div className={"flex justify-center"}>
        <p
          style={{
            color: '#313c44',
            fontFamily: 'inherit',
            fontWeight: '700',
            fontStyle: 'normal',
            fontSize: '50px',
            letterSpacing: '-.03em',
            lineHeight: '1em',
            textTransform: 'none',
            textAlign: 'center',
            marginTop: '100px',
          }}>Read and chat documents with AI <br/>Get instant answers with cited sources.</p>
      </div>
      <div className={"flex justify-center "}
           style={{
             lineHeight: '1.5em',
           }}>
        <ul>
          <li>
            Engage in dynamic discussions with AI Assistant
          </li>
          <li>
            Highlight confusing text, get an explanation.
          </li>
          <li>
            Read full-text translated papers side by side with the original
          </li>
        </ul>
      </div>

      <UploadFileComponent/>

      {/*<div className={"flex justify-center"}>*/}
      {/*  <p className={"text-gray-500"}>or</p>*/}
      {/*</div>*/}

      {/*<div className={"flex justify-center"}>*/}
      {/*  <button*/}
      {/*    onClick={async () => {*/}
      {/*      if (isAuthAlready && currentUser != null) {*/}
      {/*        await onClickStartDemo()*/}
      {/*      } else {*/}
      {/*        try {*/}
      {/*          await signInGoogle()*/}
      {/*          setTimeout(async () => {*/}
      {/*            await onClickStartDemo()*/}
      {/*          }, 1000)*/}
      {/*        } catch (e) {*/}
      {/*          message.error(e.msg)*/}
      {/*        }*/}
      {/*      }*/}
      {/*    }}*/}

      {/*    style={{*/}
      {/*      color: '#ffffff',*/}
      {/*      fontSize: '18px',*/}
      {/*      background: '#d19c33',*/}
      {/*      fontFamily: 'inherit',*/}
      {/*      width: '200px',*/}
      {/*      height: '50px',*/}
      {/*      border: '0',*/}
      {/*      borderRadius: '4px',*/}
      {/*    }}>Start Demo*/}
      {/*  </button>*/}
      {/*</div>*/}

      <div className={"flex justify-center mt-10 rounded"}>
        <video
          autoPlay
          muted={true}
          loop
          playsInline
          style={{
            maxWidth: '1024px',
            objectFit: 'contain',
            overflowClipMargin: 'content-box',
            overflow: 'clip'
          }}
          src={howToUse}/>
      </div>
      <p className={"flex justify-center text-gray-500 mt-12"}>Powered by </p>
      <div className={"flex justify-center"}>
        <img
          height={'32px'}
          alt={'OpenAI'}
          src={OpenAILogo}/>
        <img
          className={"ml-4"}
          height={'32px'}
          alt={'DeepL'}
          src={DeepLLogo}/>
        <img

          className={"ml-4"}
          height={'32px'}
          src={GoogleCloudLogo}
          alt={'Google Cloud'}/>

      </div>

      {/*for student and researcher, professtion*/}

      <div className={"flex flex-row justify-center mt-12"}>
        <div className={"rounded w-[300px] px-4"}
             style={{
               border: '1px solid #d2d4d5',
             }}>
          <p className={"font-bold text-lg"}><IdcardOutlined className={"mr-2"}/>For student</p>
          <p>Prepare for exams, get help with homework and answer multiple choice questions.</p>
        </div>
        <div className={"rounded w-[300px] px-4 mx-2"}
             style={{
               border: '1px solid #d2d4d5',
             }}>
          <p className={"font-bold text-lg"}><ExperimentOutlined className={"mr-2"}/>For Researchers</p>
          <p>Scientific papers, academic articles and books. Get the information you need for your research.</p>
        </div>

        <div className={"rounded w-[300px] px-4"}
             style={{
               border: '1px solid #d2d4d5',
             }}>

          <p className={"font-bold text-lg"}><BankOutlined className={"mr-2"}/>For Professionals</p>
          <p>Legal contracts, financial reports, manuals and training material. Ask any question to any PDF and get
            insights fast.</p>
        </div>
      </div>


      <div className={"flex flex-row justify-center mt-2"}>
        <div className={"rounded w-[300px] px-4"}
             style={{
               border: '1px solid #d2d4d5',
             }}>
          <p className={"font-bold text-lg"}><ExperimentOutlined className={"mr-2"}/>Unlimited Upload</p>
          <span>No size limit.<br></br>
            No quantity limit.<br></br>
            No questions limit.
          </span>
        </div>
        <div className={"rounded w-[300px] px-4 mx-2"}
             style={{
               border: '1px solid #d2d4d5',
             }}>
          <p className={"font-bold text-lg"}><IdcardOutlined className={"mr-2"}/>Document Translation</p>
          <p>Use Deepl or Google Translate to translate the document into your preferred language, while supporting
            dual-screen reading for efficient and convenient use</p>
        </div>
        <div className={"rounded w-[300px] px-4"}
             style={{
               border: '1px solid #d2d4d5',
             }}>

          <p className={"font-bold text-lg"}><BankOutlined className={"mr-2"}/>Collaborative</p>
          <p>Share your documents (including translated documents) with your friends for collaborative editing,
            highlighting, commenting, and more.</p>
        </div>
      </div>

      <div className={"flex justify-center mt-12"}>
        <h2 className={""}>
          <img src={celebration} height={20} width={20} className={"mr-4"}/>
          Grant <CreditIcon imgSize={40} fontSize={50} number={200}/> for New Registered User
          <img src={celebration} height={20} width={20} style={{transform: "scaleX(-1)"}} className={"ml-4"}/>
        </h2>
      </div>
      <div className={"flex flex-row justify-center mt-4 mb-24"}>
        <div className={"flex flex-col rounded-2xl p-4 mr-4 w-[320px]"}
             style={{
               border: '1px solid #d2d4d5',
             }}
        >
          <div className={"flex justify-between items-center"}>
            <span className={"text-lg font-bold"}>Free</span>
          </div>
          <span
            className={"my-1"}
            style={{
              color: 'gray',
              fontSize: '13px',
            }}>Get a taste of AI-powered reading.</span>
          <Button
            className={"my-2"}
            type={"primary"}
            onClick={()=>{
              setIsSignInModalOpen(true)
            }}
          > Try for FREE</Button>
          <span className={"mt-2"}>Unlimited files</span>
          <span className={"mt-2"}>Unlimited file size</span>
          <span className={"mt-2"}>Unlimited pages</span>
          <span className={"mt-2"}>Unlimited questions</span>
        </div>
        <div className={"flex flex-col rounded-2xl p-4 ml-4 w-[320px]"}
             style={{
               border: '1px solid #d2d4d5',
             }}>
          <span className={"text-lg font-bold"}>Pay as you go</span>
          <span
            className={"my-1"}
            style={{
              color: 'gray',
              fontSize: '13px',
            }}>No Subscribe, No Waste.</span>
          <Button
            className={"my-2"}
            type={"primary"}
            onClick={() => {
              setIsRechargeCreditModalOpen(true)
            }}
          >Recharge Credit</Button>
          <span className={"mt-2"}><CreditIcon number={10.00}/> / 1K tokens using GPT4 Model</span>
          <span className={"mt-2"}><CreditIcon number={1.00}/>  / 1K tokens using GPT3.5 Model</span>
          <span className={"mt-8"}>* <CreditIcon number={100.00}/> = CA$1.00</span>
        </div>
      </div>

      <div className={"h-[200px]"}>
      </div>
    </div>
  );
}

export default HomePage;
