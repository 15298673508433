import React, {useEffect, useState} from 'react';
import {Button, message, Modal, Popconfirm, Select, Spin, Switch, Table, Tabs} from 'antd';
import pdfService from "../../service/pdfService";
import orderService from "../../service/orderService";
import languageService from "../../service/languageService";
import {useRouteMatch} from "react-router-dom";
import {CheckCircleOutlined, DownloadOutlined, ExclamationCircleOutlined, LoadingOutlined} from "@ant-design/icons";
import {formatPrice} from "../../utils/ada";
import RechargeCreditModal from "./RechargeCreditModal";
import AddTranslateDocumentComponent from "./AddTranslateDocumentComponent";


const ManageTranslateDocumentComponent = ({
                                            id,
                                            open,
                                            onOk,
                                            onCancel,
                                            onClickSideBySideRead,
                                          }) => {
  const [pdfTranslations, setPdfTranslations] = useState([])
  const [isAddDocTransModalOpen, setIsAddDocTransModalOpen] = useState(false)


  const getFullTextTranslation = () => {
    pdfService.getFullTextTranslation(id, (res) => {
      setPdfTranslations(res.data)
      //check if all pdf translation's status is success,
      //if the status is process, then start polling to get the status
      let isAllSuccess = true
      res.data.forEach((item) => {
        if (item.status === 'processing') {
          isAllSuccess = false
        }
      })
      if (!isAllSuccess) {
        polling()
      }
    }, (error) => {
      console.log('getFullTextTranslation', error)
    })
  }

  const polling = () => {
    setTimeout(() => {
      getFullTextTranslation()
    }, 5000)
  }

  const renderStatus = (status) => {
    if (status === 'processing') {
      return (
        <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>
      )
    } else if (status === 'success') {
      return (
        <CheckCircleOutlined style={{fontSize: 20, color: 'green'}}/>
      )
    } else if (status === 'error') {
      return (
        <ExclamationCircleOutlined style={{fontSize: 20, color: 'red'}}/>
      )
    }
  }

  const renderAction = (item) => {
    if (item.status === 'success') {
      return (
        <div>
          <div className={"py-2"}>
            <span className={"mr-1"}>Side-by-Side Read: </span>
            <Switch
              size={"default"}
              defaultChecked={false}
              checkedChildren={"Enable"}
              unCheckedChildren={"Disable"}
              onChange={(checked) => {
                onClickSideBySideRead(checked ? item : null)
              }}/>
          </div>
          <a href={item.fileUrl} target="_blank" rel="noopener noreferrer"
             className={""}>Download: <DownloadOutlined style={{fontSize: 20}}/></a>
        </div>
      )
    } else {
      // processing or error
      return null
    }
  }


  return (
    <div>
      <Modal
        title="Translate Document"
        open={open}
        afterOpenChange={(isOpen) => {
          if (isOpen) {
            getFullTextTranslation()
          }
        }}
        onCancel={() => {
          onCancel()
        }}
        footer={[]}>
        <div >
          <div className={"flex"}
               style={{
                 flexDirection: 'row',
                 marginBottom: '10px',
                 justifyContent: 'space-between',
                 alignItems: 'center'
               }}>
            <span>Side-by-side Translation Reading</span>
            <Button
              type={"primary"}
              onClick={() => {
                setIsAddDocTransModalOpen(true)
              }}>Translate Document</Button>
          </div>
          <Table
            pagination={false}
            rowKey={(record) => {
              return record.id
            }}
            columns={[
              {
                title: 'Language',
                dataIndex: 'targetLang',
                key: 'targetLang',
                render: (_, record) => (
                  <span>{languageService.convertTargetLang2Name(record.targetLang)}</span>
                ),
              },
              {
                title: 'Provider',
                dataIndex: 'platform',
                key: 'platform',
                render: (_, record) => (
                  <span>{record.platform}</span>
                ),
              },
              {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (_, record) => (
                  <span>{renderStatus(record.status)}</span>
                ),
              },
              {
                title: 'Action',
                key: 'action',
                render: (_, record) => (
                  renderAction(record)
                ),
              },
            ]} dataSource={pdfTranslations}/>
        </div>
      </Modal>
      <AddTranslateDocumentComponent
        id={id}
        open={isAddDocTransModalOpen}
        onOk={() => {
          setIsAddDocTransModalOpen(false)
        }}
        onCancel={() => {
          setIsAddDocTransModalOpen(false)
        }}/>
    </div>
  )
}
export default ManageTranslateDocumentComponent;
