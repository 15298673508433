// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {connectAuthEmulator, getAuth} from "firebase/auth";
import {connectStorageEmulator, getStorage} from "firebase/storage";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD6e_rNU-WqJAqsgetlwn1eSSiMwv7kD8U",
  authDomain: "allenyan-89cab.firebaseapp.com",
  databaseURL: "https://allenyan-89cab-default-rtdb.firebaseio.com",
  projectId: "allenyan-89cab",
  storageBucket: "allenyan-89cab.appspot.com",
  messagingSenderId: "525151268242",
  appId: "1:525151268242:web:6617255dbf6931b2dd254b",
  measurementId: "G-1ND00SWK02"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();
const storage = getStorage()

// if (window.location.hostname === "localhost") {
//   connectAuthEmulator(auth, "http://localhost:9099");
//   connectStorageEmulator(storage, "localhost", 9199)
// }
export const firebaseApp = app
