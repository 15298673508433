import React, {useContext, useEffect, useState} from 'react';
import {
  Button,
  Col,
  Dropdown,
  Input,
  message,
  Modal,
  Popconfirm, Popover,
  Row,
  Space,
  Spin,
  Table,
  Tooltip,
  Upload,
} from 'antd';
import {
  DeleteOutlined, DownOutlined, LeftOutlined, LoadingOutlined, MoreOutlined, RightOutlined,
  ShareAltOutlined, SmileOutlined, TwitterCircleFilled, TwitterOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import pdfService from "../../service/pdfService";
import {getAuth} from "firebase/auth";
import SharePDFModal from "../component/SharePDFModal";
import UploadFileComponent from "../component/UploadFileComponent";
import IndexPage from "./IndexPage";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";
import logo from "../../assets/img/logo.png";
import HomeTemplateContext from "../context/HomeTemplateContext";
import HomePage from "./HomePage";
import UserInfoView from "../profile/UserInfoView";
import {Crisp} from "crisp-sdk-web";
import {TwitterIcon} from "react-share";

const moment = require('moment');

const DashboardV2Page = ({}) => {

  const {
    isAuthAlready,
    currentUser,
  } = useContext(HomeTemplateContext)
  const {id} = useParams();
  const history = useHistory()
  const [data, setData] = useState([])
  const [originData, setOriginData] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isShowingShareModal, setIsShowingShareModal] = useState(false)
  const [hoveringPdfId, setHoveringPdfId] = useState(null)
  const [isLeftSideBarOpen, setIsLeftSideBarOpen] = useState(true)
  const [isCrispVisible, setIsCrispVisible] = useState(false)

  useEffect(() => {
    if (isCrispVisible){
      Crisp.chat.show()
      Crisp.chat.open()
    } else{
      Crisp.chat.close()
      Crisp.chat.hide()
    }
  }, [isCrispVisible]);

  useEffect(() => {
    async function init() {
      await getIndex()
      // await getMyDetail()
    }

    init()
    Crisp.chat.hide()
  }, [])

  const getIndex = async () => {
    try {
      const res = await pdfService.getIndex(page, pageSize);
      setOriginData(res.data)
      setData(res.data)
    } catch (e) {
      message.error(e.msg)
    }
  }
  // const getMyDetail = async ()=>{
  //   const res = await userService.myDetailAsync()
  //   setCurrentUserInfo(res.data)
  // }


  const deleteUserPdf = (pdfId) => {
    pdfService.delete(pdfId, (res) => {
      getIndex()
    }, (res) => {
      message.error(res.msg)
    })
  }

  const items = [
    {
      key: '1',
      label: (
        <a onClick={async () => {
          setIsShowingShareModal(true)
        }}>
          <ShareAltOutlined className={"mr-2"}/> Share
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <Popconfirm
          title={"Are you sure to delete?"}
          onConfirm={() => {
            deleteUserPdf(hoveringPdfId)
          }}>
          <a>
            <DeleteOutlined className={"mr-2"}/> Delete
          </a>
        </Popconfirm>
      )
    }
  ]

  const PDFItem = (item) => {
    const isSelecting = item.id === id
    return (
      <div className={"flex flex-row justify-between items-center py-2"}
           style={{
             backgroundColor: isSelecting ? '#e6f7ff' : 'white',
           }}
           onMouseOver={() => {
             data.find((i) => i.id === item.id).isHoveing = true
             setHoveringPdfId(item.id)
             setData([...data])
           }}
           onMouseOut={() => {
             data.find((i) => i.id === item.id).isHoveing = false
             setHoveringPdfId(null)
             setData([...data])
           }}>
        <div
          onClick={() => {
            if (isSelecting) {
              return
            }
            history.push(`/dashboard/${item.id}`)
          }}
          className={"flex flex-col cursor-pointer px-2 overflow-x-hidden"}>
          <span className={"text-sm line-clamp-1"}>{item.fileName}</span>
          <span
            className={"text-xs text-gray-500 line-clamp-1"}>{moment(item.created).format('YYYY-MM-DD HH:mm:ss')}</span>
        </div>

        <div className={"flex justify-center"}>
          <Dropdown
            menu={{
              items,
            }}>
            <Button
              type={"text"}
              className={"px-2 mx-2"}
              style={{
                display: item.isHoveing ? 'block' : 'none',
              }}>
              <MoreOutlined
                style={{fontSize: '18px'}}
              />
            </Button>
          </Dropdown>
        </div>
      </div>
    )

  }

  const emailAvatar = (email) => {
    return (
      <Popover placement={"top"} content={<UserInfoView/>}>
        <div
          onClick={() => {
            history.push("/profile")
          }}
          className={"flex flex-row items-center justify-center w-[25px] h-[25px] rounded-full bg-[#34c567] cursor-pointer"}>
          <span className={"text-[14px] text-white"}>{email[0].toUpperCase()}</span>
        </div>
      </Popover>
    )
  }

  const renderUserInfo = () => {
    if (isAuthAlready) {
      if (currentUser) {
        //已登录
        return (
          <div className={"flex flex-col justify-between"}>
            <div className={"flex flex-row mx-2 mt-2 items-center"}>
              {emailAvatar(currentUser?.email)}
              <span
                onClick={() => {
                  window.location.href = "/home"
                }}
                className={"text-xs text-gray-500 cursor-pointer ml-2"}>Home</span>
              <span
                onClick={() => {
                  setIsCrispVisible(!isCrispVisible)
                }}
                className={"text-xs text-gray-500 ml-2 cursor-pointer"}>Feedback</span>
              <TwitterOutlined
                onClick={()=>{
                  window.open("https://twitter.com/alinlinlink")
                }}
                className={"text-[14px] ml-2 "}
                style={{
                color: 'gray',
              }}/>
            </div>
          </div>
        );
      } else {
        //未登录
        return (
          <div></div>
        );
      }
    } else {
      return (
        <div>
          <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>
        </div>
      )
    }
  }

  const filterPdfName = (value) => {
    if (value === "") {
      setData(originData)
      return
    }
    //ignore case
    setData(originData.filter((item) => {
      return item.fileName.toLowerCase().includes(value.toLowerCase())
    }))
  }

  return (
    <div>
      <div className={"flex flex-row justify-center"}>
        {/*左侧栏*/}
        <div className={"flex flex-col overflow-x-hidden justify-center h-screen"}
             style={{
               width: `${isLeftSideBarOpen ? '200px' : '0px'}`,
               minWidth: `${isLeftSideBarOpen ? '200px' : '0px'}`,
               borderRight: '1px solid #e8e8e8',
             }}>
          <div
            style={{
              height: '44px',
              minHeight: '44px',
              maxHeight: '44px',
            }}
            className={"flex flex-row justify-center items-center"}>
            <img src={logo} alt={"Pepper Reader"} height={20} width={20}/>
            <span className={"m-2 text-[20px] cursor-pointer"}
                  onClick={() => {
                    window.location.href = "/home"
                  }}
            >Pepper Reader</span>
          </div>
          <div className={"flex justify-center"}
               style={{
                 borderTop: '1px solid #e8e8e8',
               }}>
            <Button
              className={"w-[180px] mt-2"}
              disabled={getAuth().currentUser == null}
              icon={<UploadOutlined/>}
              size={"small"}
              onClick={() => {
                setIsModalOpen(true)
              }}>Upload</Button>
          </div>
          <div className={"mt-2 flex justify-center"}>
            <Input
              style={{
                width: '180px',
              }}
              placeholder="Search"
              allowClear
              size={"small"}
              onChange={(e) => {
                filterPdfName(e.target.value)
              }}
            />
          </div>

          <div className={"flex-grow overflow-y-scroll overflow-x-hidden mt-2"}
               style={{
                 borderBottom: '1px solid #e8e8e8',
               }}>
            {data != null && data.length > 0 && data.map((item, index) => {
              return (
                <div key={index}>
                  {PDFItem(item)}
                </div>
              )
            })}
          </div>
          <div className={"h-[60px]"}>
            {renderUserInfo()}
          </div>
        </div>


        {id == null && <div className={"flex-grow justify-center items-center overflow-y-scroll h-screen"}>
          <HomePage/>
        </div>}
        {id != null &&
          <div className={"flex-grow"}>
            <IndexPage
              id={id}
            />
          </div>}
        {/*左侧缩小*/}
        <div
          style={{
            position: 'fixed',
            left: `${isLeftSideBarOpen ? '200px' : '0px'}`,
            top: '50vh',
          }}>
          <span
            style={{
              transform: 'rotate(-90deg)',
              fontSize: '20px',
              cursor: 'pointer',
              backgroundColor: '#f0f2f5',
              border: '1px solid #e8e8e8',
              padding: '12px 2px',
            }}
            onClick={() => {
              setIsLeftSideBarOpen(!isLeftSideBarOpen)
            }}
          >
            {isLeftSideBarOpen ?
              <LeftOutlined className={"text-[12px]"}/> :
              <RightOutlined className={"text-[12px]"}/>}
          </span>
        </div>
      </div>
      <Modal
        title="Upload Your File"
        open={isModalOpen}
        footer={[]}
        onOk={() => {
          setIsModalOpen(false)
        }}
        onCancel={() => {
          setIsModalOpen(false)
        }}>
        <UploadFileComponent/>
      </Modal>
      <SharePDFModal
        pdfId={hoveringPdfId}
        open={isShowingShareModal}
        onOk={() => {
          setIsShowingShareModal(false)
        }}
        onCancel={() => {
          setIsShowingShareModal(false)
        }}
      />

    </div>
  );
};

export default DashboardV2Page;
