import httpRequest from '../../utils/httpRequest';

export default class AdminDocumentService {

  static findList(page, pageSize) {
    return httpRequest.get({
      url: `/api/admin/document/list?page=${page}&pageSize=${pageSize}`,
    })
  }

  static findQuestionsList(page, pageSize) {
    return httpRequest.get({
      url: `/api/admin/document/questions?page=${page}&pageSize=${pageSize}`,
    })
  }
  static findPageTranslations(page, pageSize) {
    return httpRequest.get({
      url: `/api/admin/document/pageTranslations?page=${page}&pageSize=${pageSize}`,
    })
  }
}
