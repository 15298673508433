import {Button, InputNumber, List, message, Modal} from "antd";
import React, {useContext, useEffect, useState} from "react";
import orderService from "../../service/orderService";
import OrderService from "../../service/orderService";
import CreditIcon from "./CreditIcon";
import HomeTemplateContext from "../context/HomeTemplateContext";
import {formatPrice} from "../../utils/ada";


const RechargeCreditModal = ({open, onOk, onCancel}) => {

  const {
    currentUserInfo
  } = useContext(HomeTemplateContext)

  const [products, setProducts] = useState([])
  const [cad, setCad] = useState(5)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function getProducts() {
      const res = await orderService.getProducts()
      setProducts(res.data)
    }

    if (open) {
      getProducts()
    }
  }, [open]);

  const onClickProduct = async (index) => {
    const product = products[index]
    const redirectUrl = window.location.href
    const res = await OrderService.createRechargeOrder(product.productId, redirectUrl)
    const {checkoutUrl} = res.data
    window.open(checkoutUrl, '_blank')
  }

  const onClickConfirm = async () => {
    setIsLoading(true)
    try {
      const redirectUrl = window.location.href
      const res = await OrderService.createRechargeOrderV2(cad, redirectUrl)
      const {checkoutUrl} = res.data
      window.open(checkoutUrl, '_blank')
      setIsLoading(false)
    } catch (e) {
      message.error(e.msg)
      setIsLoading(false)
    }
  }


  return (
    <Modal
      title={`Recharge Credit`}
      open={open}
      onOk={() => {
        onOk()
      }}
      onCancel={() => {
        onCancel()
      }}
      footer={[]}
    >
      <div className={"flex flex-col justify-center"}>

        <span>Current Credit: <CreditIcon number={currentUserInfo?.account?.credit}/></span>

        <div className={"flex justify-center mt-8"}>
          <span><CreditIcon
            imgSize={50}
            fontSize={65}
            number={formatPrice(cad * 100)}/></span>
        </div>
        <div className={"flex justify-center mt-2"}>
          <InputNumber
            addonBefore={"CA$"}
            min={5}
            max={99}
            step={5}
            onChange={(value) => {
              setCad(value)
            }}
            style={{
              width: '120px'
            }}
            defaultValue={cad}
          />
        </div>

        <div className={"flex justify-center mt-12"}>
          <Button
            loading={isLoading}
            onClick={onClickConfirm}
            className={"w-[120px]"}
            type={'primary'}
          >Confirm</Button>
        </div>
      </div>


      {/*<List*/}
      {/*  grid={{*/}
      {/*    gutter: 16,*/}
      {/*    xs: 3,*/}
      {/*    sm: 3,*/}
      {/*    md: 3,*/}
      {/*    lg: 3,*/}
      {/*    xl: 3,*/}
      {/*    xxl: 3,*/}
      {/*  }}*/}
      {/*  dataSource={products}*/}
      {/*  renderItem={(item, index) => (*/}
      {/*    <List.Item>*/}
      {/*      <div*/}
      {/*        className={"cursor-pointer text-center border-b-2 py-10 rounded"}*/}
      {/*        style={{*/}
      {/*          border: '1px solid #e8e8e8',*/}
      {/*        }}*/}
      {/*        onClick={async () => {*/}
      {/*          onClickProduct(index)*/}
      {/*        }}>*/}
      {/*        <div className={"font-bold text-[20px] flex  justify-center items-center"}>*/}
      {/*          <CreditIcon number={item.credits}/>*/}
      {/*          /!*<span className={"ml-1"}>{item.credits}</span>*!/*/}
      {/*        </div>*/}
      {/*        <div className={"mt-4"}>*/}
      {/*          {item.price}*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </List.Item>*/}
      {/*  )}*/}
      {/*/>*/}
    </Modal>
  )
}
export default RechargeCreditModal
