import {formatPrice} from "../../utils/ada";

const credit = require("../../assets/img/credit.png");
const React = require("react");


const CreditIcon = ({
                      className,
                      number,
                      imgSize,
                      fontSize,
                    }) => {
  return (
    <span style={{
      // textAlign: 'center',
      // fontSize: '16px',
      // fontStyle: 'bold',
      // verticalAlign: 'middle',
      // height:'40px'
    }}>
      <img
        alt={"credit"}
        height={imgSize == null ? 15 : imgSize}
        width={imgSize == null ? 15 : imgSize}
        className={"mx-0.5"}
        style={{
          display: 'inline-block',
          verticalAlign: 'baseline'
        }}
        src={credit}/>
      <span style={{
        fontWeight: 'bold',
        fontSize: fontSize == null ? 16 : fontSize,
      }}>
        {formatPrice(number)}
      </span>
    </span>
  )
}

export default CreditIcon;
