import {Table} from "antd";
import React, {useEffect, useState} from "react";
import adminUserService from "../../service/admin/adminUserService";
import adminDocumentService from "../../service/admin/adminDocumentService";
import moment from "moment/moment";
import {message} from "antd";


const PageTranslateView = ({}) => {

  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const findList = async (page, pageSize) => {
    try {
      const res = await adminDocumentService.findPageTranslations(page, pageSize)
      setList(res.data.list)
      setTotal(res.data.total)
    } catch (e) {
      message.error(e.message)
    }
  }

  useEffect(() => {
    findList(page, pageSize)
  }, [])

  return (
    <div className={"flex overflow-y-scroll h-screen "}>
      <Table
        pagination={{
          onChange: async (page, pageSize) => {
            await findList(page, pageSize)
          },
          pageSize: pageSize,
          total: total,
        }}
        dataSource={list}
        rowKey={(record) => {
          return record.id
        }}
        columns={[
          {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            render: (_, record) => (
              <span>{record.id}</span>
            ),
          },
          {
            title: 'pdfId',
            dataIndex: 'pdfId',
            key: 'pdfId',
            render: (_, record) => (
              <span>{record.pdfId}</span>
            ),
          },
          {
            title: 'pageIndex',
            dataIndex: 'pageIndex',
            key: 'pageIndex',
            render: (_, record) => (
              <span>{record.pageIndex}</span>
            ),
          },
          {
            title: 'lang',
            dataIndex: 'lang',
            key: 'lang',
            render: (_, record) => (
              <span>{record.lang}</span>
            ),
          },
          {
            title: 'text',
            dataIndex: 'text',
            key: 'text',
            render: (_, record) => (
              <span
                style={{
                  width: '300px',
                  height: '100px',
                }}>{record.text}</span>
            ),
          },
          {
            title: 'updated',
            dataIndex: 'updated',
            key: 'updated',
            render: (_, record) => (
              <span>{moment(record.updated).format('YYYY-MM-DD HH:mm:ss')}</span>
            ),
          }
        ]}
      />
    </div>
  )
}

export default PageTranslateView;
