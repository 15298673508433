import React, {useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ConfigProvider,} from "antd";
import {firebaseApp} from "./service/firebase";

//user
import HomeTemplate from "./page/component/HomeTemplate";
import DashboardPage from "./page/pdf/DashboardPage";
import HomePage from "./page/pdf/HomePage";
import Profile from "./page/profile";
import Resume from "./page/resume";
import ShareAcceptPage from "./page/pdf/ShareAcceptPage";
import PaymentSuccessPage from "./page/payment/SuccessPage";
import PaymentCancelPage from "./page/payment/CancelPage";
import HomeTemplateContext from "./page/context/HomeTemplateContext";
import {getAuth} from "firebase/auth";
import userService from "./service/userService";
import RechargeCreditModal from "./page/component/RechargeCreditModal";
import SignInView from "./page/auth/SignInView";
import FinishSignUpView from "./page/auth/FinishSignUpView";

//admin
import AdminDashboard from "./page/admin/Dashboard";
import AdminTemplate from "./page/admin/AdminTemplate";
import AdminUserListView from "./page/admin/UserListView";
import AdminDocumentListView from "./page/admin/DocumentListView";
import AdminDocumentQuestionsView from "./page/admin/DocumentQuestionsView";
import AdminPageTranslateView from "./page/admin/PageTranslateView";
import {Crisp} from "crisp-sdk-web";
Crisp.configure('f93c276d-b6cc-4125-8c65-0ec6b7d18002')

const App = () => {
  /**
   * auth是否已经初始化
   */
  const [isAuthAlready, setIsAuthAlready] = useState(false)
  /**
   * auth中的用户信息
   */
  const [currentUser, setCurrentUser] = useState(null)
  /**
   * firestore中的用户信息
   */
  const [currentUserInfo, setCurrentUserInfo] = useState(null)
  const [isRechargeCreditModalOpen, setIsRechargeCreditModalOpen] = useState(false)
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false)

  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      setCurrentUser(user)
      setIsAuthAlready(true)
    })
    getAuth().onIdTokenChanged((user) => {

    })
    userService.myDetail((res) => {
      setCurrentUserInfo(res.data)
    }, (err) => {
    })
  }, [])

  return (
    <ConfigProvider theme={{
      token: {
        fontFamily: 'sans-serif',
        colorPrimary: '#34c567'
      }
    }}>
      <HomeTemplateContext.Provider value={{
        isAuthAlready,
        currentUser,
        currentUserInfo,
        setCurrentUserInfo,
        setIsRechargeCreditModalOpen,
        setIsSignInModalOpen,
      }}>
        <Router>
          <Switch>
            <Route path="/admin" render={() =>
              <AdminTemplate>
                <Switch>
                  <Route path="/admin/dashboard" component={AdminDashboard}/>
                  <Route path="/admin/user" component={AdminUserListView}/>
                  <Route path="/admin/document" component={AdminDocumentListView}/>
                  <Route path="/admin/question" component={AdminDocumentQuestionsView}/>
                  <Route path="/admin/pageTranslate" component={AdminPageTranslateView}/>
                </Switch>
              </AdminTemplate>
            }/>
            <Route path="/" render={() =>
              <Switch>
                <Route exact path="/dashboard" component={DashboardPage}/>
                <Route exact path="/dashboard/:id" component={DashboardPage}/>
                <Route exact path="/finishSignUp" component={FinishSignUpView}/>
                <HomeTemplate>
                  <Route exact path="/" component={HomePage}/>
                  <Route exact path="/home" component={HomePage}/>
                  <Route exact path="/profile" component={Profile}/>
                  {/*<Route exact path="/pdf/:id" component={IndexPage}/>*/}
                  <Route exact path="/resume" component={Resume}/>
                  <Route exact path="/pdf/share/:id" component={ShareAcceptPage}/>
                  <Route exact path="/payment/success" component={PaymentSuccessPage}/>
                  <Route exact path="/payment/cancel" component={PaymentCancelPage}/>
                  {/*<Route component={NoMatch}/>*/}
                </HomeTemplate>
              </Switch>
            }/>
          </Switch>
        </Router>
        {isRechargeCreditModalOpen &&
          <RechargeCreditModal
            open={isRechargeCreditModalOpen}
            onOk={() => {
              setIsRechargeCreditModalOpen(false)
            }}
            onCancel={() => {
              setIsRechargeCreditModalOpen(false)
            }}/>
        }

        {isSignInModalOpen &&
          <SignInView
            open={isSignInModalOpen}
            onOK={() => {
              setIsSignInModalOpen(false)
            }}
            onCancel={() => {
              setIsSignInModalOpen(false)
            }}
          />
        }

      </HomeTemplateContext.Provider>
    </ConfigProvider>
  )
    ;
}


export default App
