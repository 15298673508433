import httpRequest from '../../utils/httpRequest';

export default class AdminUserService {

  static findList(page, pageSize) {
    return httpRequest.get({
      url: `/api/admin/user/list?page=${page}&pageSize=${pageSize}`,
    })
  }
}
