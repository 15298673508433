import {RightOutlined} from "@ant-design/icons";
import CreditIcon from "../component/CreditIcon";
import {Button, Popover} from "antd";
import React, {useContext, useEffect} from "react";
import HomeTemplateContext from "../context/HomeTemplateContext";
import userService from "../../service/userService";
import {isEmpty} from "lodash";


const UserInfoView = () => {

  const {
    currentUserInfo,
    setCurrentUserInfo,
    setIsRechargeCreditModalOpen,
  } = useContext(HomeTemplateContext)

  useEffect(() => {
    userService.myDetail((res) => {
      setCurrentUserInfo(res.data)
    }, (err) => {
      console.error(err)
    })
  }, []);

  const EmailAvatar = ({
                         email,
                         className,
                         style,
                       }) => {
    return (
      <div
        className={`flex flex-row items-center justify-center w-[25px] h-[25px] rounded-full bg-[#34c567] cursor-pointer ${className}`}
        style={style}
      >
        <span className={"text-[14px] text-white"}>{email[0].toUpperCase()}</span>
      </div>
    )
  }

  const RechargeButton = ({}) => {
    return (
      <Button
        onClick={() => {
          setIsRechargeCreditModalOpen(true)
        }}
      >Recharge Credit</Button>
    )
  }

  return (
    <div className={"flex flex-col w-[300px] px-4"}>

      <div className={"flex flex-row items-center justify-between pb-2"}
           style={{
             borderBottom: '1px solid #e8e8e8',
           }}>

        <div className={"flex flew-row items-center"}>
          <EmailAvatar
            email={currentUserInfo.email}
            className={"mr-2"}
            style={{}}
          />
          {isEmpty(currentUserInfo.displayName) && <span>{currentUserInfo.email}</span>}
          {!isEmpty(currentUserInfo.displayName) &&
            <div className={"flex flex-col"}>
              <span>{currentUserInfo.displayName}</span>
              <span className={"text-xs text-gray-400"}>{currentUserInfo.email}</span>
            </div>
          }
        </div>

        <RightOutlined/>
      </div>

      <div className={"flex items-center justify-between py-4"}>
        <span>
          Credit: <CreditIcon number={currentUserInfo?.account?.credit}/>
        </span>
        <RechargeButton/>
      </div>

      <div className={"flex items-center justify-center py-2"}
           style={{
             borderTop: '1px solid #e8e8e8',
           }}
      >
        <Button
          className={"w-[300px]"}
        >Sign out</Button>
      </div>


    </div>
  )
}

export default UserInfoView;
