import {useEffect, useRef, useState} from "react";
import PSPDFKit from "pspdfkit";
import {message} from "antd";
import {isNumber} from "lodash";

/**
 * API Document :https://pspdfkit.com/api/web/PSPDFKit.Instance.html
 * @param className
 * @param style
 * @param pdf
 * @param translationPdfFileUrl
 * @param onClickTranslate
 * @param onClickChat
 * @param onMouseLeftDown
 * @param onExportXFDF
 * @param onExtractTextSuccess
 * @param onDocumentLoaded
 * @param translationWords
 * @returns {JSX.Element}
 * @constructor
 */
const PdfViewerComponent = ({
                              pdf,
                              translationAnnotation,
                              onClickTranslate,
                              onClickChat,
                              onMouseLeftDown,
                              onExportXFDF,
                              onDocumentLoaded,

                              extractPageIndex,
                              onExtractPageIndexSuccess,
                              onCurrentPageIndexChange,
                            }) => {
  const KEY_ZOOM_LEVEL = pdf.id + '_zoomLevel'
  const KEY_PAGE_NUMBER = pdf.id + '_pageNumber'

  const containerRef = useRef(null);
  const [pspdfkitInstance, setPspdfkitInstance] = useState(null);

  const [textSelection, setTextSelection] = useState(null);
  const [currentSelectedAnnotation, setCurrentSelectedAnnotation] = useState(null);
  const [colorHex, setColorHex] = useState('#f0a9a7');

  const [isCreateAnnotation, setIsCreateAnnotation] = useState(false);
  const [isClickLookup, setIsClickLookup] = useState(false);
  const [isClickCopy, setIsClickCopy] = useState(false);
  const [isClickChat, setIsClickChat] = useState(false);

  const [isClickLookupFromAnnotation, setIsClickLookupFromAnnotation] = useState(false);
  const [isClickChatFromAnnotation, setIsClickChatFromAnnotation] = useState(false);

  const covertColorHexToRGB = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)
    return [r, g, b]
  }

  const extractPageText = async (pageIndex) => {
    if (pspdfkitInstance == null) {
      console.error('pspdfkitInstance is null')
      return
    }
    //提取文本
    const textLines = await pspdfkitInstance.textLinesForPageIndex(pageIndex)
    let pageText = ''
    textLines.forEach((textLine, textLineIndex) => {
      pageText += textLine.contents + '\n'
    });
    return pageText
  }

  // const extractFullText = async (instance) => {
  //   if (pdf == null || instance == null) {
  //     return
  //   }
  //   if (pdf.needExtractText) {
  //     //提取文本
  //     let fullTextArray = []
  //     for (let i = 0; i < instance.totalPageCount; i++) {
  //       const textLines = await instance.textLinesForPageIndex(i)
  //       let pageText = ''
  //       textLines.forEach((textLine, textLineIndex) => {
  //         pageText += textLine.contents + '\n'
  //       });
  //       fullTextArray.push(pageText)
  //     }
  //     onExtractTextSuccess(fullTextArray)
  //   }
  // }

  /**
   * 用户刷新页面后 恢复上次的状态
   */
  const recoveryStatus = (instance) => {
    if (instance == null) {
      return
    }
    // set the zoom level with percentage
    let pageNumber = 0;
    // let zoomLevel = 1;
    try {
      pageNumber = parseInt(localStorage.getItem(KEY_PAGE_NUMBER))
      if (!isNumber(pageNumber) || isNaN(pageNumber) || pageNumber < 0 || pageNumber > instance.totalPageCount) {
        pageNumber = 0
      }
      // zoomLevel = parseFloat(localStorage.getItem(KEY_ZOOM_LEVEL))
      // if (!isNumber(zoomLevel) || isNaN(zoomLevel) || zoomLevel <= 0 || zoomLevel > 10) {
      //   zoomLevel = 1
      // }
    } catch (e) {
      pageNumber = 0
      // zoomLevel = 1
    }
    // console.log('recoveryStatus', pageNumber, zoomLevel)
    const state = instance.viewState;
    const newState = state.set("currentPageIndex", pageNumber)
      // .set("zoom", zoomLevel)
    instance.setViewState(newState);
  }

  /**
   * todo 暂时不做
   * @returns {*[]}
   */
  const getAnnotationToolButtons = () => {
    let buttons = []
    const lookupNode = document.createElement('node')
    lookupNode.innerText = 'Lookup'
    buttons.push({
      id: 'custom',
      type: "custom",
      node: lookupNode,
      title: 'Lookup',
      onPress: () => {
        setIsClickLookupFromAnnotation(true)
      }
    })

    const chatNode = document.createElement('node')
    chatNode.innerText = 'Chat'
    buttons.push({
      id: 'custom',
      type: "custom",
      node: chatNode,
      title: 'Chat',
      onPress: () => {
        setIsClickChatFromAnnotation(true)
      }
    })
    return buttons
  }

  const customAnnotationButtons = [
    {
      type: "custom",
      id: "my-button",
      icon: '/assets/img/circle-red.png',
      onPress: (event) => {
        setColorHex('#f0a9a7')
        setIsCreateAnnotation(true)
      }
    },
    {
      type: "custom",
      id: "my-button",
      icon: '/assets/img/circle-blue.png',
      onPress: (event) => {
        setColorHex('#abc4fa')
        setIsCreateAnnotation(true)
      }
    },
    {
      type: "custom",
      id: "my-button",
      icon: '/assets/img/circle-yellow2.png',
      onPress: (event) => {
        setColorHex('#f3b96d')
        setIsCreateAnnotation(true)
      }
    },
    {
      type: "custom",
      id: "my-button",
      icon: '/assets/img/circle-yellow.png',
      onPress: (event) => {
        setColorHex('#f0e278')
        setIsCreateAnnotation(true)
      }
    },
    {
      type: "custom",
      id: "my-button2",
      icon: '/assets/img/search_white.png',
      onPress: async (event) => {
        setIsClickLookup(true)
      }
    },
    {
      type: "custom",
      id: "my-button2",
      icon: '/assets/img/comment_white.png',
      onPress: async (event) => {
        setIsClickChat(true)
      }
    },
    {
      type: "custom",
      id: "my-button2",
      icon: '/assets/img/copy_white.png',
      onPress: async (event) => {
        setIsClickCopy(true)
      }
    }
  ]

  useEffect(() => {
    if (!isCreateAnnotation || pspdfkitInstance == null) {
      return
    }
    const rgb = covertColorHexToRGB(colorHex)
    textSelection.getText().then(text => {
      textSelection.getSelectedRectsPerPage().then(rectsPerPage => {
        rectsPerPage.map(({pageIndex, rects}) => {
          // We need to create one annotation per page.
          const annotation = new PSPDFKit.Annotations.HighlightAnnotation({
            pageIndex: pageIndex,
            boundingBox: PSPDFKit.Geometry.Rect.union(rects),
            rects: rects,
            color: new PSPDFKit.Color({r: rgb[0], g: rgb[1], b: rgb[2]}),
            customData: {
              text: text
            }
          });
          pspdfkitInstance.create(annotation);
        });
      });
    })
    setIsCreateAnnotation(false)
  }, [isCreateAnnotation])


  /**
   * 在翻译页点击添加注释后 执行以下代码
   */
  useEffect(() => {
    if (!translationAnnotation || pspdfkitInstance == null || textSelection == null) {
      return
    }
    const rgb = covertColorHexToRGB(colorHex)
    textSelection.getSelectedRectsPerPage().then(rectsPerPage => {
      rectsPerPage.map(({pageIndex, rects}) => {
        // We need to create one annotation per page.
        const annotation = new PSPDFKit.Annotations.HighlightAnnotation({
          pageIndex: pageIndex,
          boundingBox: PSPDFKit.Geometry.Rect.union(rects),
          rects: rects,
          color: new PSPDFKit.Color({r: rgb[0], g: rgb[1], b: rgb[2]}),
          customData: {
            comment: translationAnnotation
          }
        });
        pspdfkitInstance.create(annotation);
      });
    });
  }, [translationAnnotation])

  useEffect(() => {
    if (!isClickLookup || pspdfkitInstance == null) {
      return
    }
    textSelection.getText().then(text => {
      console.log('clickLookup', text);
      onClickTranslate(text)
    })
    setIsClickLookup(false)
  }, [isClickLookup])

  useEffect(() => {
    if (!isClickChat || pspdfkitInstance == null) {
      return
    }
    textSelection.getText().then(text => {
      if (text == null) {
        return
      }
      text = text.replace(/\n/g, '')
      console.log('clickChat', text);
      onClickChat(text)
    })
    setIsClickChat(false)
  }, [isClickChat]);

  useEffect(() => {
    if (!isClickCopy) {
      return
    }
    textSelection.getText().then(text => {
      console.log('copy', text);
      navigator.clipboard.writeText(text).then(() => {
        message.success('Copy success')
      }, () => {
        message.error('Copy failed')
      })
    })
    setIsClickCopy(false)
  }, [isClickCopy]);

  useEffect(() => {
    if (!isClickLookupFromAnnotation || pspdfkitInstance == null) {
      return
    }
    if (currentSelectedAnnotation == null || currentSelectedAnnotation.customData == null) {
      return
    }
    const text = currentSelectedAnnotation.customData.text
    console.log('clickAnnotationLookup', text);
    onClickTranslate(text)
    setIsClickLookupFromAnnotation(false)
  }, [isClickLookupFromAnnotation]);

  useEffect(() => {
    if (!isClickChatFromAnnotation || pspdfkitInstance == null) {
      return
    }
    if (currentSelectedAnnotation == null ||
      currentSelectedAnnotation.customData == null ||
      currentSelectedAnnotation.customData.text == null) {
      return
    }
    let text = currentSelectedAnnotation.customData.text
    //remove \n
    text = text.replace(/\n/g, '')
    console.log('clickAnnotationChat', text);
    onClickChat(text)
    setIsClickChatFromAnnotation(false)
  }, [isClickChatFromAnnotation]);


  useEffect(() => {
    async function extractTask() {
      if (extractPageIndex == null) {
        console.error('extractPageIndex is null')
        return
      }
      const text = await extractPageText(extractPageIndex)
      onExtractPageIndexSuccess(text, extractPageIndex)
    }
    extractTask()
  }, [extractPageIndex]);

  useEffect(() => {
    const container = containerRef.current; // This `useRef` instance will render the PDF.
    let PSPDFKit, instance;
    (async function () {
      PSPDFKit = await import("pspdfkit")
      PSPDFKit.unload(container) // Ensure that there's only one PSPDFKit instance.
      // https://pspdfkit.com/api/web/PSPDFKit.Configuration.html
      instance = await PSPDFKit.load({
        // Container where PSPDFKit should be mounted.
        container,
        // The document to open.
        document: pdf.fileUrl,
        // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
        styleSheets: [
          './css/pspdfkit.css'
        ],
        XFDF: pdf.xfdfString,
        XFDFKeepCurrentAnnotations: true,
        // instantJSON: pdf.instantJSON,
        annotationToolbarItems: (annotation, {defaultAnnotationToolbarItems, hasDesktopLayout}) => {
          // console.log('annotation', annotation)
          // console.log('annotation.customData', annotation.customData)
          const newItems = []
          defaultAnnotationToolbarItems.forEach((item) => {
            if (item.type === 'opacity' || item.type === 'blend-mode' ||
              item.type === 'annotation-note'
            ) {
              return
            }
            newItems.push(item)
          })
          return newItems
          // return [...defaultAnnotationToolbarItems, ...getAnnotationToolButtons()]
          // return defaultAnnotationToolbarItems
        },
        inlineTextSelectionToolbarItems: ({defaultItems, hasDesktopLayout}, selection) => {
          console.log('defaultItems', defaultItems)
          return customAnnotationButtons;
        },
        // customUI:{
        //   [PSPDFKit.UIElement.Sidebar]: {
        //     [PSPDFKit.SidebarMode.ANNOTATIONS]({containerNode}) {
        //       containerNode.style.padding = "0.5rem";
        //       // if (!containerNode.querySelector(".MyCustomSidebarComponentHeader")) {
        //       //   const header = document.createElement("div");
        //       //   header.classList.add("MyCustomSidebarComponentHeader");
        //       //   containerNode.prepend(header);
        //       // }
        //       return {
        //         node: containerNode,
        //         onRenderItem({itemContainerNode, item: annotation}) {
        //           console.log('itemContainerNode', itemContainerNode)
        //           // const footerAuthor = itemContainerNode.querySelector(".PSPDFKit-Sidebar-Annotations-Footer span");
        //           // Change the format of the footer text by prefixing it with `Creator:` and removing the date.
        //           // footerAuthor.textContent = `Creator: ${annotation.creatorName}`;
        //           // Add the aria label to the annotation icon.
        //           // const annotationIcon = itemContainerNode.querySelector(".PSPDFKit-Icon");
        //           // annotationIcon.setAttribute("aria-label", `Icon for an annotation created by ${annotation.creatorName}.`);
        //         }
        //       }
        //     }
        //   }
        // }
        initialViewState: new PSPDFKit.ViewState({
          zoom: PSPDFKit.ZoomMode.FIT_TO_WIDTH
        })
      });

      setPspdfkitInstance(instance)
      console.log('instance', instance)

      const items = instance.toolbarItems;
      const newToolbarItems = []
      items.forEach((item) => {
        if (item.type === 'multi-annotations-selection' ||
          item.type === 'pan' ||
          item.type === 'annotate' ||
          item.type === 'ink' ||
          item.type === 'highlighter' ||
          item.type === 'text-highlighter' ||
          item.type === 'ink-eraser' ||
          item.type === 'signature' ||
          item.type === 'image' ||
          item.type === 'stamp' ||
          item.type === 'stamp' ||
          item.type === 'note' ||
          item.type === 'text' ||
          item.type === 'callout' ||
          item.type === 'line' ||
          item.type === 'link' ||
          item.type === 'arrow' ||
          item.type === 'rectangle' ||
          item.type === 'ellipse' ||
          item.type === 'polygon' ||
          item.type === 'cloudy-polygon' ||
          item.type === 'polyline' ||
          item.type === 'print' ||
          item.type === 'document-editor' ||
          item.type === 'document-crop'
        ) {
          return
        }
        newToolbarItems.push(item)
      })
      instance.setToolbarItems(newToolbarItems)
      instance.addEventListener("annotations.create", async createdAnnotations => {
        const xfdf = await instance.exportXFDF()
        onExportXFDF(xfdf)
      });
      instance.addEventListener("annotations.update", async updatedAnnotations => {
        const xfdf = await instance.exportXFDF()
        onExportXFDF(xfdf)
      });
      instance.addEventListener("annotations.delete", async deletedAnnotations => {
        const xfdf = await instance.exportXFDF()
        onExportXFDF(xfdf)
      });
      instance.addEventListener("annotations.press", (event) => {
        if (event != null && event.annotation != null) {
          setCurrentSelectedAnnotation(event.annotation)
          if (event.annotation.customData != null) {
            let text = null
            if (event.annotation.customData.comment != null) {
              text = event.annotation.customData.comment
            } else {
              //暂时不展示
              //text = event.annotation.customData.text
            }
            if (text == null) {
              return
            }
            //remove \n \r\n br
            text = text.replace(/\n/g, '')
            text = text.replace(/\r\n/g, '')
            text = text.replace(/<br>/g, '')

            const left = event.annotation.boundingBox.left
            const right = event.annotation.boundingBox.right
            const top = event.annotation.boundingBox.top
            const bottom = event.annotation.boundingBox.bottom
            const tipNode = document.createElement("div")
            // tipNode.className = "annotationTip"
            tipNode.style.fontSize = '0.7em'
            tipNode.style.background = '#fff'
            tipNode.style.border = '1px solid #ccc'
            tipNode.style.borderRadius = '4px'
            tipNode.style.marginTop = '6px'
            tipNode.style.padding = '4px 4px 5px 4px'
            tipNode.style.lineHeight = 'normal'
            tipNode.style.textAlign = 'left'
            // tipNode.style.maxWidth = right - left + 'px'
            tipNode.innerText = text
            const item = new PSPDFKit.CustomOverlayItem({
              id: "video-instructions",
              node: tipNode,
              pageIndex: event.annotation.pageIndex,
              position: new PSPDFKit.Geometry.Point({x: left, y: bottom})
            });
            instance.setCustomOverlayItem(item);
          }

        }
      });
      instance.addEventListener("viewState.zoom.change", zoom => {
        localStorage.setItem(KEY_ZOOM_LEVEL, zoom)
      })
      instance.addEventListener("page.press", event => {
        onMouseLeftDown()
        try {
          instance.removeCustomOverlayItem("video-instructions");
        } catch (e) {
          //未找到不需要提示
        }
      })
      instance.addEventListener("viewState.currentPageIndex.change", currentPageIndex => {
        console.log('viewState.currentPageIndex.change', currentPageIndex)
        localStorage.setItem(KEY_PAGE_NUMBER, currentPageIndex)
        onCurrentPageIndexChange(currentPageIndex)
      })
      instance.addEventListener("textSelection.change", textSelection => {
        if (textSelection != null) {
          setTextSelection(textSelection)
        }
      });
      // 所有的events
      // https://pspdfkit.com/api/web/PSPDFKit.Instance.html#addEventListener
      recoveryStatus(instance)
      // extractFullText(instance)
      onDocumentLoaded()
    })();
    return () => PSPDFKit && PSPDFKit.unload(container)
  }, [pdf]);


  // This div element will render the document to the DOM.
  return <div ref={containerRef} style={{width: "100%", height: "100vh"}}/>
}

export default PdfViewerComponent;
