/**
 *
 * @param price number
 */
function formatPrice(price) {
  if (price == null) {
    return null
  }
  return (Math.floor(price * 100) / 100).toFixed(2);
}

/**
 *
 * @param price
 * @return {string|null}
 */
function formatPrice2(price) {
  if (price == null) {
    return null
  }
  return parseFloat((Math.floor(price * 100) / 100).toFixed(2))
}


function debounce(fn, wait) {
  let timer = null;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, wait)
    // timer = setTimeout(fn, wait);
  };
}

const isWord = (str) => {
  return /^[a-zA-Z]+$/.test(str);
}

export {
  debounce,
  formatPrice,
  isWord,
}