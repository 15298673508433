import React, {useEffect, useState} from 'react';
import {Button, message, Modal, Popconfirm, Select, Spin, Switch, Table, Tabs} from 'antd';
import pdfService from "../../service/pdfService";
import orderService from "../../service/orderService";
import languageService from "../../service/languageService";
import {useRouteMatch} from "react-router-dom";
import {CheckCircleOutlined, DownloadOutlined, ExclamationCircleOutlined, LoadingOutlined} from "@ant-design/icons";
import {formatPrice} from "../../utils/ada";
import RechargeCreditModal from "./RechargeCreditModal";
import LanguageSelect from "./LanguageSelect";


const AddTranslateDocumentComponent = ({
                                         id,
                                         open,
                                         onOk,
                                         onCancel
                                       }) => {
  const [targetLang, setTargetLang] = useState(localStorage.getItem('defaultLanguage') || navigator.language)

  const [preDocTranslateDeeplResult, setPreDocTranslateDeeplResult] = useState(null)
  const [preDocTranslateGoogleResult, setPreDocTranslateGoogleResult] = useState(null)
  const [tabCurrentKey, setTabCurrentKey] = useState('google')
  const [isRechargeCreditModalOpen, setIsRechargeCreditModalOpen] = useState(false)

  const preDocTranslate = async (key) => {
    setTabCurrentKey(key)
    const res = await pdfService.preDocTranslate(id, targetLang, key)
    if (key === 'deepl') {
      setPreDocTranslateDeeplResult(res.data)
    }
    if (key === 'google') {
      setPreDocTranslateGoogleResult(res.data)
    }
  }

  // const onClickPay = async () => {
  //   const res = await orderService.createTranslateDocumentOrder(id, targetLang, tabCurrentKey)
  //   const checkoutUrl = res.data.checkoutUrl
  //   window.open(checkoutUrl, '_blank')
  // }

  const onClickPayByCredit = async () => {
    try {
      const res = await orderService.createTranslateDocumentOrderByCredit(id, targetLang, tabCurrentKey)
      message.success('Pay successfully')
      onOk()
    } catch (e) {
      message.error(e.msg)
    }
  }


  const renderTranslateDocument = (preDocTranslateResult) => {
    return (
      <div>
        {preDocTranslateResult == null && <div>
          <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>
        </div>}
        {preDocTranslateResult != null && <div>
          <div className={"flex justify-between items-center mb-2"}>
            <span>Target Language: </span>
            <LanguageSelect
              onChange={(value) => {
                setTargetLang(value)
              }}
            />
          </div>
          {tabCurrentKey === 'deepl' &&
            <div className={"flex justify-between mb-2"}>
              <span>Documents Chars Count:</span>
              <span>{preDocTranslateResult.charsCount}</span>
            </div>}
          {tabCurrentKey === 'google' &&
            <div>

              <div className={"flex justify-between mb-2"}>
                <span>Documents Total Page:</span>
                <span>{preDocTranslateResult.totalPageNumber}</span>
              </div>
              <div className={"flex justify-between mb-2"}>
                <span>Free Translate Page Usage:</span>
                <span>{preDocTranslateResult.freeDocPages}</span>
              </div>
            </div>
          }
          <div className={"flex justify-between mb-2"}>
            <span>Current Credit:</span>
            <span>{formatPrice(preDocTranslateResult.userCredit)}</span>
          </div>

          <div className={"flex flex-col mt-8"}>
            {preDocTranslateResult.enablePayByCredit &&
              <Popconfirm
                title={`Are you sure to pay by credit?`}
                okText="Yes"
                cancelText="No"
                onConfirm={async () => {
                  onClickPayByCredit()
                }}>

                <Button
                  className={"my-2"}
                  type={"primary"}
                  size={'large'}>
                  {preDocTranslateResult.estimateCredit === '0.00' ? 'Free Translate' : `Pay Credit ${formatPrice(preDocTranslateResult.estimateCredit)}`}
                </Button>

              </Popconfirm>
            }
            {!preDocTranslateResult.enablePayByCredit &&
              <Button
                className={"my-2"}
                type={"primary"}
                size={'large'}
                onClick={() => {
                  setIsRechargeCreditModalOpen(true)
                }}
              >
                Recharge Credit {formatPrice(preDocTranslateResult.estimateCredit)}
              </Button>
            }

            {/*{preDocTranslateResult.estimateCredit !== '0.00' &&*/}
            {/*  <Button*/}
            {/*    className={"my-2"}*/}
            {/*    size={'large'}*/}
            {/*    onClick={async () => {*/}
            {/*      onClickPay()*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Pay CA${formatPrice(preDocTranslateResult.estimatePriceIncludeTaxAndTradeFee)}*/}
            {/*  </Button>*/}
            {/*}*/}
          </div>
        </div>}
      </div>
    )
  }


  return (
    <div>
      <Modal
        title="Translate Document"
        open={open}
        afterOpenChange={async (isOpen) => {
          if (isOpen) {
            await preDocTranslate(tabCurrentKey)
          }
        }}
        onCancel={() => {
          onCancel()
        }}
        footer={[]}>
        <Tabs
          onChange={async (key) => {
            await preDocTranslate(key)
          }}
          size={"small"}
          defaultActiveKey={tabCurrentKey}
          activeKey={tabCurrentKey}
          items={[
            {
              label: 'Google',
              key: 'google',
              children: (renderTranslateDocument(preDocTranslateGoogleResult)),
            },
            {
              label: 'DeepL',
              key: 'deepl',
              children: (renderTranslateDocument(preDocTranslateDeeplResult)),
            }
          ]}/>
      </Modal>
      <RechargeCreditModal
        open={isRechargeCreditModalOpen}
        onOk={() => {
          setIsRechargeCreditModalOpen(false);
        }}
        onCancel={() => {
          setIsRechargeCreditModalOpen(false);
        }}/>
    </div>
  )
}
export default AddTranslateDocumentComponent;
