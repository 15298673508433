import {Button, Input, message, Modal} from "antd";
import React, {useEffect, useState} from "react";
import {
  browserLocalPersistence,
  getAuth,
  isSignInWithEmailLink,
  setPersistence,
  signInWithEmailLink,
} from "firebase/auth";


const FinishSignUpView = () => {

  const init = async () => {
    try {
      const auth = getAuth();
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          message.error('Please provide your email for confirmation')
          return
        }
        const auth = getAuth()
        await setPersistence(auth, browserLocalPersistence)
        await signInWithEmailLink(auth, email, window.location.href)
        window.localStorage.removeItem('emailForSignIn');
        message.success('Sign in success');
        window.location.href = '/dashboard'
      } else {
        message.error('Please provide your email for confirmation')
      }
    } catch (e) {
      console.error(e)
      message.error('Sign in failed')
    }
  }

  useEffect(() => {
    init()
  }, []);

  return (
    <div></div>
  )
}

export default FinishSignUpView
