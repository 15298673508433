import {Button, Input, message, Modal} from "antd";
import React, {useState} from "react";
import {
  browserLocalPersistence,
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  setPersistence,
  signInWithPopup,
  sendSignInLinkToEmail,
} from "firebase/auth";
import google from "../../assets/img/google.png";
import facebook from "../../assets/img/facebook.png";


const SignInView = ({
                      open,
                      onOK,
                      onCancel,
                    }) => {

  /**
   * state: SignIn,  WaitingForEmailConfirmation
   */
  const [state, setState] = useState("SignIn")

  const SignIn = () => {
    const [email, setEmail] = useState('')

    const onClickGoogleSignIn = async () => {
      try {
        const auth = getAuth()
        await setPersistence(auth, browserLocalPersistence)
        await signInWithPopup(auth, new GoogleAuthProvider())
        message.success('Sign in success');
        setTimeout(() => {
          window.location.href = '/dashboard'
        })
      } catch (e) {
        console.log(e)
      }
    }
    const onClickFacebookSignIn = async () => {
      try {
        const auth = getAuth()
        await setPersistence(auth, browserLocalPersistence)
        await signInWithPopup(auth, new FacebookAuthProvider())
        message.success('Sign in success');
        setTimeout(() => {
          window.location.href = '/dashboard'
        })
      } catch (e) {
        console.log(e)
      }
    }

    const onClickEmailSignIn = async () => {
      const actionCodeSettings = {
        url: `${process.env.REACT_APP_PUBLIC_URL}/finishSignUp`,
        handleCodeInApp: true,
      };
      try {
        const auth = getAuth();
        await sendSignInLinkToEmail(auth, email, actionCodeSettings)
        message.success('Email sent')
        window.localStorage.setItem('emailForSignIn', email);
        setState("WaitingForEmailConfirmation")
      } catch (e) {
        console.error(e)
      }
    }

    return (
      <div className={"flex flex-col py-10 px-5"}>
        <div className={"text-[22px] font-bold"}>Sign In</div>
        <div className={"text-lg text-gray-700"}>to continue to ppreader.ca</div>
        <div
          className={"flex flex-row items-center cursor-pointer h-[44px] px-4 mt-6 rounded"}
          style={{
            border: "1px solid #f1f1f1",
          }}
          onClick={async () => {
            onClickGoogleSignIn()
          }}
        ><img src={google} height={20} width={20} className={"mr-4"}/> Continue with Google
        </div>
        <div
          className={"flex flex-row items-center cursor-pointer h-[44px] px-4 mt-2 rounded"}
          style={{
            border: "1px solid #f1f1f1",
          }}
          onClick={async () => {
            onClickFacebookSignIn()
          }}
        ><img src={facebook} height={20} width={20} className={"mr-4"}/> Continue with Facebook
        </div>

        <div className={"my-4 flex flex-col items-center"}>
          or
        </div>

        <div className={""}
        >Email address
        </div>
        <Input
          size={"large"}
          rootClassName={"mt-2"}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
        />

        <Button
          className={"mt-2"}
          type={"primary"}
          size={"large"}
          onClick={async () => {
            onClickEmailSignIn()
          }}
        >Continue</Button>
        {/*<div>No account? Sign up</div>*/}
      </div>
    )
  }

  const WaitingForEmailConfirmationView = () => {
    const emailForSignIn = window.localStorage.getItem('emailForSignIn')

    const onClickEmailSignIn = async () => {
      const actionCodeSettings = {
        url: `${process.env.REACT_APP_PUBLIC_URL}/finishSignUp`,
        handleCodeInApp: true,
      };
      try {
        const auth = getAuth();
        await sendSignInLinkToEmail(auth, emailForSignIn, actionCodeSettings)
        message.success('Email sent')
        window.localStorage.setItem('emailForSignIn', emailForSignIn);
        setState("WaitingForEmailConfirmation")
      } catch (e) {
        console.error(e)
      }
    }

    return (
      <div className={"flex flex-col"}>
        <h1>Check your email</h1>
        <div className={""}>We sent an email to <a>{emailForSignIn}</a></div>
        <div className={""}>Click the link in the email to finish signing in.</div>
        <div>Check your spam folder if you don't see the email.</div>
        <div className={"flex mt-12"}>
          <div>Didn't receive the email?</div>
          <div
            className={"cursor-pointer"}
            onClick={async () => {
              onClickEmailSignIn()
            }}><a className={"ml-2"}>Send again</a>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Modal
      className={"w-[400px]"}
      open={open}
      footer={[]}
      onOk={() => {
        onOK()
      }}
      onCancel={() => {
        onCancel()
      }}>

      {state === "SignIn" && <SignIn/>}
      {state === "WaitingForEmailConfirmation" && <WaitingForEmailConfirmationView/>}

    </Modal>
  )
}

export default SignInView
