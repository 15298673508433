import httpRequest from '../utils/httpRequest';

export default class LookupService {
  static dictionary(word, success, error) {
    return httpRequest.get({
      url: `/api/lookup/dictionary?word=${word}`,
    })
  }

  static wikiLookup(word, success, error) {
    return httpRequest.get({
      url: `/api/lookup/wikipedia?word=${word}`,
    })
  }

}
