import httpRequest from '../utils/httpRequest';

export default class PDFPageTranslateService {

  static createByGoogle = async (pdfId, lang, pageIndex, text) => {
    return httpRequest.post({
      url: `/api/pdf/pageTranslate/createByGoogle`,
      params: {
        pdfId,
        lang,
        pageIndex,
        text,
      }
    })
  }

  static createByOpenAI(idToken, pdfId, lang, pageIndex, text, model, onMessage, onError) {
    const url = process.env.REACT_APP_API_URL + `/api/pdf/pageTranslate/createByOpenAI?pdfId=${pdfId}&lang=${lang}&pageIndex=${pageIndex}&text=${text}&idToken=${idToken}&model=${model}`
    const eventSource = new EventSource(url);
    eventSource.onmessage = (event) => {
      const newMessage = JSON.parse(event.data);
      onMessage(newMessage)
      //判断是否结束，主动关闭,服务端不会超市报错
      if (newMessage.content == null) {
        eventSource.close()
      }
    };
    eventSource.onerror = (event) => {
      eventSource.close();
      onError(event)
    };
  }

  static findAll = async (pdfId, lang) => {
    return httpRequest.get({
      url: `/api/pdf/pageTranslate/findAll?pdfId=${pdfId}&lang=${lang}`,
    })
  }

}
