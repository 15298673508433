import {Table} from "antd";
import React, {useEffect, useState} from "react";
import adminUserService from "../../service/admin/adminUserService";
import adminDocumentService from "../../service/admin/adminDocumentService";
import moment from "moment/moment";
import {message} from "antd";


const DocumentQuestionsView = ({}) => {

  const [questions, setQuestions] = useState([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const findList = async (page, pageSize) => {
    try {
      const res = await adminDocumentService.findQuestionsList(page, pageSize)
      setQuestions(res.data.list)
      setTotal(res.data.total)
    } catch (e) {
      message.error(e.message)
    }
  }

  useEffect(() => {
    findList(page, pageSize)
  }, [])

  const renderMessage = (messages) => {
    let result = '';
    for (let i = 0; i < messages.length; i++) {
      result += `${messages[i].role}:${messages[i].content}\n`
    }
    return result;
  }

  return (
    <div className={"flex overflow-y-scroll h-screen "}>
      <Table
        pagination={{
          onChange: async (page, pageSize) => {
            await findList(page, pageSize)
          },
          pageSize: pageSize,
          total: total,
        }}
        className={"flex-grow"}
        dataSource={questions}
        rowKey={(record) => {
          return record.id
        }}
        columns={[
          {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            render: (_, record) => (
              <span>{record.id}</span>
            ),
          },
          {
            title: 'userId',
            dataIndex: 'userId',
            key: 'userId',
            render: (_, record) => (
              <span>{record.userId}</span>
            ),
          },
          {
            title: 'pdfId',
            dataIndex: 'pdfId',
            key: 'pdfId',
            render: (_, record) => (
              <span>{record.pdfId}</span>
            ),
          },
          {
            title: 'messages',
            dataIndex: 'messages',
            key: 'message',
            render: (_, record) => (
              <pre
                className={"px-4 py-2"}
                style={{
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                  maxWidth: '430px',
                  minWidth: '430px',
                  height: '100px',
                  overflow: 'auto',
                  textAlign: 'left',
                  fontFamily: 'sans-serif',
                  fontSize: '14px',
                }}>{renderMessage(record.messages)}</pre>
            ),
          },
          {
            title: 'updated',
            dataIndex: 'updated',
            key: 'updated',
            render: (_, record) => (
              <span>{moment(record.updated).format('YYYY-MM-DD HH:mm:ss')}</span>
            ),
          }
        ]}
      />
    </div>
  )
}

export default DocumentQuestionsView;
