import httpRequest from '../utils/httpRequest';

export default class OrderService {
  static getProducts() {
    return httpRequest.get({
      url: `/api/order/products`,
    })
  }

  static createRechargeOrder(productId, redirectUrl) {
    return httpRequest.post({
      url: '/api/order/recharge/create',
      params: {
        productId: productId,
        redirectUrl: redirectUrl
      }
    })
  }

  static createRechargeOrderV2(cad, redirectUrl) {
    return httpRequest.post({
      url: '/api/order/recharge/create/v2',
      params: {
        cad: cad,
        redirectUrl: redirectUrl
      }
    })
  }



  // static async createTranslateDocumentOrder(pdfId, targetLang, platform) {
  //   return httpRequest.post({
  //     url: `/api/order/translateDocument/create`,
  //     params: {
  //       pdfId: pdfId,
  //       targetLang: targetLang,
  //       platform: platform,
  //     }
  //   })
  // }


  static async createTranslateDocumentOrderByCredit(pdfId, targetLang, platform) {
    return httpRequest.post({
      url: `/api/order/translateDocument/create/credit`,
      params: {
        pdfId: pdfId,
        targetLang: targetLang,
        platform: platform,
      }
    })
  }
}
