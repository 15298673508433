import {useContext, useEffect, useState} from 'react';
import {Button, Input, message, Modal, Popconfirm, Progress, Table, Tabs,} from 'antd';
import userService from "../../service/userService";
import CreditIcon from "../component/CreditIcon";


const UsageView = () => {

  const [creditPayments, setCreditPayments] = useState([])


  const getCreditPayments = async () => {
    const res = await userService.getCreditPayments()
    try {
      setCreditPayments(res.data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getCreditPayments()
  }, [])

  return (
    <div className={"px-40"}>
      <h1>Daily Usage</h1>
      <Table
        dataSource={creditPayments}
        rowKey={(record) => record.createdDay}
        columns={[
          {
            title: 'Date',
            dataIndex: 'createdDay',
            key: 'createdDay',
          }, {
            title: 'Credit',
            dataIndex: 'credit',
            key: 'credit',
            render: (credit) => {
              return <CreditIcon number={credit}/>
            }
          }
        ]}
      />
    </div>
  )
}

export default UsageView;
