import React, {useContext, useState} from 'react'
import {Button, Input, message, Space, Upload} from 'antd'
import HomeTemplateContext from "../context/HomeTemplateContext";
import {InboxOutlined} from "@ant-design/icons";
import pdfService from "../../service/pdfService";
import userService from "../../service/userService";
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";

const UploadFileComponent = ({
                               className,
                             }) => {

  const {
    isAuthAlready,
    currentUser,
    setIsSignInModalOpen,
  } = useContext(HomeTemplateContext)

  const [inputValue, setInputValue] = useState('')
  const [isDragUploading, setIsDragUploading] = useState(false)
  const [isUploading, setIsUploading] = useState(false)


  const uploadFile = async (options) => {
    try {
      //先获取一次 userId
      setIsDragUploading(true)
      const result = await userService.getUserId();
      const userId = result.data.userId
      const storage = getStorage();
      const file = options.file
      const filePath = `pdf/${userId}/${file.name}`
      const storageRef = ref(storage, filePath);
      const uploadTask = await uploadBytes(storageRef, file)
      const uploadRef = uploadTask.ref
      const downloadUrl = await getDownloadURL(uploadRef)
      const res = await pdfService.createPdf(downloadUrl, file.name, filePath, file.size)
      const pdfId = res.data.pdfId
      await pollingCheckPdfStatus(pdfId)
      // setIsDragUploading(false)
    } catch (e) {
      message.error(e.msg)
      // setIsDragUploading(false)
    }
  }

  const uploadFileByUrl = async () => {
    try {
      setIsUploading(true)
      const res = await pdfService.createByUrl(inputValue)
      setIsUploading(false)
      const pdfId = res.data.pdfId
      await pollingCheckPdfStatus(pdfId)
    } catch (e) {
      setIsUploading(false)
      message.error(e.msg)
    }
  }

  const pollingCheckPdfStatus = async (pdfId) => {
    try {
      const res = await pdfService.getById(pdfId)
      const status = res.data.status
      if (status === 'SUCCESS') {
        window.location.href = '/dashboard/' + pdfId
      } else if (status === 'FAILED') {
        message.error('Upload failed')
      } else if (status === 'PENDING') {
        setTimeout(() => {
          pollingCheckPdfStatus(pdfId)
        }, 3000)
      } else {
        console.log('error status', status)
      }
    } catch (e) {
      message.error(e.msg)
    }
  }

  return (
    <div className={className}>
      <div className={"flex flex-col"}>
        <div className={`flex justify-center ${isDragUploading ? 'mb-10' : ''}`}>
          <Upload.Dragger
            className={"w-[400px]"}
            name={'file'}
            accept={'.pdf,.doc,.docx,.pptx,.ppt'}
            multiple={false}
            customRequest={async (options) => {
              if (isAuthAlready && currentUser != null) {
                await uploadFile(options)
              } else {
                setIsSignInModalOpen(true)
                // await signInGoogle()
                // await uploadFile(options)
              }
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined/>
            </p>
            <p className="ant-upload-text">Click or drag and drop file to upload</p>
            {/*<p className="ant-upload-hint">*/}
            {/*</p>*/}
            {/*<p className={"text-sm mt-8 text-gray-500"}>Support: .pdf .doc .docx</p>*/}
          </Upload.Dragger>
        </div>
        <div className={"flex justify-center mt-4"}>
          <Space.Compact
            className={"w-[400px]"}
          >
            <Input
              className={""}
              placeholder={"Upload files through URL"}
              value={inputValue}
              size={"large"}
              onChange={(e) => {
                setInputValue(e.target.value)
              }}
            />
            <Button
              type={"primary"}
              disabled={inputValue === ''}
              size={"large"}
              loading={isUploading}
              onClick={async () => {
                if (isAuthAlready && currentUser != null) {
                  await uploadFileByUrl()
                } else {
                  setIsSignInModalOpen(true)
                  // await signInGoogle()
                  // await uploadFileByUrl()
                }
              }}
            >Upload</Button>
          </Space.Compact>
        </div>

        <div className={"flex justify-center"}>
          <span className={"text-sm mt-4 text-gray-500"}>Support: .pdf .doc .docx</span>
        </div>
      </div>
    </div>
  )
}

export default UploadFileComponent
