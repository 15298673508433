import React, {useContext, useEffect, useState} from 'react';
import {Link,} from 'react-router-dom';
import {Button, Dropdown, List, Menu, message, Modal, Select, Spin} from 'antd';
import {DownOutlined, LoadingOutlined, LogoutOutlined, SmileOutlined} from '@ant-design/icons';
import userService from "../../service/userService";
import HomeTemplateContext from "../context/HomeTemplateContext";
import {getAuth,} from "firebase/auth";


const AdminTemplate = ({children}) => {

  const {
    isAuthAlready,
    currentUser,
    currentUserInfo,
    setCurrentUserInfo,
    setIsSignInModalOpen,
  } = useContext(HomeTemplateContext)

  const onClickLogin = async () => {
    setIsSignInModalOpen(true)
  }

  const onClickLogout = async () => {
    await getAuth().signOut()
    message.success('Logout success')
    setTimeout(() => {
      window.location.href = '/'
    })
  }

  return (
    <div className={"flex flex-row h-screen w-screen"}>
      <Menu
        theme="dark"
        mode="vertical">
        <Menu.Item key="User">
          <Link to="/admin/user">User</Link>
        </Menu.Item>
        <Menu.Item key="Document">
          <Link to="/admin/document">Document</Link>
        </Menu.Item>
        <Menu.Item key="Question">
          <Link to="/admin/question">Question</Link>
        </Menu.Item>
        <Menu.Item key="Page Translate">
          <Link to="/admin/pageTranslate">Page Translate</Link>
        </Menu.Item>
      </Menu>
      <div>
        {children}
      </div>
    </div>
  )
}
export default AdminTemplate
