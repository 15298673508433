import httpRequest from '../utils/httpRequest';

export default class PDFService {
  static getIndex(page, pageSize) {
    return httpRequest.get({
      url: `/api/pdf/index?pageSize=${pageSize}&page=${page}`,
    })
  }

  static startDemo() {
    return httpRequest.get({
      url: `/api/pdf/startDemo`,
    })
  }

  static async createPdf(fileUrl, fileName, filePath, fileSize) {
    return await httpRequest.post({
      url: `/api/pdf/create`,
      params: {
        fileUrl: fileUrl,
        fileName: fileName,
        filePath: filePath,
        fileSize: fileSize,
      },
    })
  }

  static async createByUrl(url) {
    return await httpRequest.post({
      url: `/api/pdf/createByUrl`,
      params: {
        url: url,
      },
    })
  }

  static delete(pdfId, success, error) {
    httpRequest.post({
      url: `/api/pdf/delete`,
      params: {
        id: pdfId
      },
    }).then((res) => {
      success(res);
    }).catch((res) => {
      error(res);
    })
  }

  static getById(id) {
    return httpRequest.get({
      url: `/api/pdf/getById?id=${id}`,
    })
  }


  static sendMessageStream(id, appendMessage, modal, idToken, onMessage, onError) {
    const url = process.env.REACT_APP_API_URL + `/api/pdf/sendMessage/stream?id=${id}&idToken=${idToken}&appendMessage=${appendMessage}&modal=${modal}`
    const eventSource = new EventSource(url);
    eventSource.onmessage = (event) => {
      const newMessage = JSON.parse(event.data);
      onMessage(newMessage)
      //判断是否结束，主动关闭,服务端不会超市报错
      if (newMessage.content == null) {
        eventSource.close()
      }
    };
    eventSource.onerror = (event) => {
      eventSource.close();
      onError(event)
    };
  }

  static summarize(pdfId, lang, idToken, onMessage, onError) {
    const url = process.env.REACT_APP_API_URL + `/api/pdf/summarize/stream?pdfId=${pdfId}&lang=${lang}&idToken=${idToken}`
    const eventSource = new EventSource(url);
    eventSource.onmessage = (event) => {
      const newMessage = JSON.parse(event.data);
      onMessage(newMessage)
      //判断是否结束，主动关闭,服务端不会超市报错
      if (newMessage.content == null) {
        eventSource.close()
      }
    };
    eventSource.onerror = (event) => {
      eventSource.close();
      onError(event)
    };
  }

  static getMessages(id, success, error) {
    httpRequest.get({
      url: `/api/pdf/getMessages?id=${id}`,
    }).then((res) => {
      success(res);
    }).catch((res) => {
      error(res);
    })
  }

  static async clearMessages(id) {
    return httpRequest.post({
      url: `/api/pdf/clearMessages`,
      params: {
        id: id,
      }
    })
  }

  static getMind(id, success, error) {
    httpRequest.get({
      url: `/api/pdf/getMind?id=${id}`,
    }).then((res) => {
      success(res);
    }).catch((res) => {
      error(res);
    })
  }

  static saveMind(id, mind, success, error) {
    httpRequest.post({
      url: `/api/pdf/saveMind`,
      params: {
        id: id,
        mind: mind,
      }
    }).then((res) => {
      success(res);
    }).catch((res) => {
      error(res);
    })
  }

  static genMind(id, mind, success, error) {
    httpRequest.post({
      url: `/api/pdf/genMind`,
      params: {
        id: id,
        mind: mind,
      }
    }).then((res) => {
      success(res);
    }).catch((res) => {
      error(res);
    })
  }


  static uploadDocument(success, error) {
    httpRequest.post({
      url: `/api/pdf/uploadDocument`,
      params: {}
    }).then((res) => {
      success(res);
    }).catch((res) => {
      error(res);
    })
  }

  /**
   * 获取全文翻译数据
   * 如果没有全文翻译数据，就在页面展示开始全文翻译
   *
   * {
   *   "status": "processing", // success,
   *   "fileUrls": [
   *     "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
   *     "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
   *   ],
   * }
   * @param id
   * @param success
   * @param error
   */
  static getFullTextTranslation(id, success, error) {
    httpRequest.get({
      url: `/api/pdf/getFullTextTranslation?id=${id}`,
    }).then((res) => {
      success(res);
    }).catch((res) => {
      error(res);
    })
  }

  /**
   * 开始全文翻译
   * 立刻返回，之后通过轮询获取翻译结果 getFullTextTranslation接口
   * @param id
   * @param targetLang
   * @param success
   * @param error
   */
  static startFullTextTranslation(id, targetLang, success, error) {
    httpRequest.post({
      url: '/api/pdf/startFullTextTranslation',
      params: {
        id: id,
        targetLang: targetLang,
      }
    }).then((res) => {
      success(res);
    }).catch((res) => {
      error(res);
    })
  }

  static async startFullTextTranslationV2(id, targetLang, success, error) {
    httpRequest.post({
      url: '/api/pdf/startFullTextTranslation/v2',
      params: {
        id: id,
        targetLang: targetLang,
      }
    }).then((res) => {
      success(res);
    }).catch((res) => {
      error(res);
    })
  }

  /**
   *
   * @param id -> pdf's id
   * @param success
   * @param error
   */
  static importAnnotations(id, success, error) {
    httpRequest.get({
      url: `/api/pdf/getAllAnnotations?id=${id}`,
    }).then((res) => {
      success(res);
    }).catch((res) => {
      error(res);
    })
  }

  /**
   *
   * @param id -> pdf's id
   * @param success
   * @param error
   */
  static exportAnnotations(id, xfdfString, success, error) {
    httpRequest.post({
      url: `/api/pdf/saveAnnotation`,
      params: {
        id: id,
        xfdfString: xfdfString,
      }
    }).then((res) => {
      success(res);
    }).catch((res) => {
      error(res);
    })
  }

  static exportAnnotationsAsync(id, xfdfString) {
    return httpRequest.post({
      url: `/api/pdf/saveAnnotation/v2`,
      params: {
        id: id,
        xfdfString: xfdfString,
      }
    })
  }

  static exportInstantJSON(id, instantJSON) {
    return httpRequest.post({
      url: `/api/pdf/saveInstantJSON`,
      params: {
        id: id,
        instantJSON: instantJSON,
      }
    })
  }


  static translateStream(selectedText, lang, idToken, onMessage, onError) {
    let url = process.env.REACT_APP_API_URL + `/api/pdf/translateByOpenAI?text=${selectedText}&lang=${lang}&idToken=${idToken}`
    const eventSource = new EventSource(url);
    eventSource.onmessage = (event) => {
      const newMessage = JSON.parse(event.data);
      onMessage(newMessage)

      //判断是否结束，主动关闭,服务端不会超市报错
      if (newMessage.content == null) {
        eventSource.close()
      }
    };

    eventSource.onerror = (event) => {
      eventSource.close();
      onError(event)
    };
  }

  static async translateByGoogle(text, lang) {
    return httpRequest.post({
      url: `/api/pdf/translateByGoogle`,
      params: {
        text: text,
        lang: lang,
      }
    })
  }


  static async preDocTranslate(pdfId, targetLang, platform) {
    return httpRequest.get({
      url: `/api/pdf/preDocTranslate?pdfId=${pdfId}&targetLang=${targetLang}&platform=${platform}`,
      params: {
        pdfId: pdfId,
        targetLang: targetLang,
      }
    })
  }

  static async paymentSuccess(sessionId) {
    return httpRequest.get({
      url: `/api/payment/success?sessionId=${sessionId}`,
    })
  }

  static async getRechargeProducts() {
    return httpRequest.get({
      url: `/api/payment/getRechargeProducts`,
    })
  }

}
