import {Button, Input, message, Modal, Popconfirm, Progress, Tabs,} from 'antd';
import UsageView from "./UsageView";
import SettingView from "./SettingView";


const Profile = () => {
  return (
    <div
      className={"bg-white px-10 py-10"}>
      <Tabs
        tabPosition={"left"}
        items={[{
          label: `Setting`,
          key: '1',
          children: <SettingView/>,
        }, {
          label: `Usage`,
          key: '2',
          children: <UsageView/>,
        }]}
      />
    </div>
  );
};

export default Profile;
